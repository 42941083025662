import React, { useState, useEffect, useLayoutEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { USBButton, USBProgressIndicator, USBNotification, USBTabs } from '@usb-shield/components-react/dist/cjs';
import { connect } from "react-redux";
import Axios from 'axios';
import LoadingHelper from '../../utilities/loading-helper';
import GraphQlQuery from '../../utilities/graphQL-query';
import { getSvcServiceURL, getDocumentmgmtServiceURL, getAuthServiceURL,getSvcOldServiceURL } from '../../config/connections';
import UserInformationForm from './../employee-registration/UserInformationForm';
import UserRoleDropdownList from './../employee-registration/user-role-dropdown-list';
import AssignPermissions from './../assign-permission/AssignPermissions';
import AssignPermissionsCDC from './../assign-permission/AssignPermissionsCDC';
import { USBGrid, USBColumn } from '@usb-shield/components-react'
import verifyEmailAlreadyExist from '../../shared/thunk/email-validation';
import { FormValidationHelpers } from '../../utilities/form-validation-helpers';
import AssignPermissionHelper from '../assign-permission/assign-permission-helper';
import { transmitIframeMessage } from '../../components/utility/transmit-message';
import {FeatureFlagsContext} from '../../components/Hooks/useFeatureFlags';
import ConfirmationDialog from '../../components/shared/modal-wrapper';


const EditUserProfile = (props) => {
    let userRoleDropdownList = null;
    const userProfileRef = React.useRef();
    const countryListData = useSelector((state) => state.userProfileInformation.countryList);
    const userProfileSavedData = useSelector((state) => state.userProfileInformation.profileData);
    const isSuperAdmin = useSelector((state) => state.currentUser.isSuperAdmin);
    const isCustomer = useSelector((state) => state.currentUser.isCustomer);
    const isFrequentVendor = useSelector((state) => state.currentUser.isFrequentVendor);
    const initialProfileData = useSelector((state) => state.userProfileInformation.profileServerData);
    const operatingUser = useSelector((state) => state.currentUser.user.sub);
    const entitlementServerData = useSelector((state) => state.entitlement.entitlementServerResponse);
    const currentUser = useSelector(state => state.currentUser.user);
    const dispatch = useDispatch();
    const [countryList, setCountryList] = useState([]);
    const [parentCompanyList, setParentCompanyList] = useState([]);
    const [parentCompanyListFull, setParentCompanyListFull] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [userNotEnrollErrorMessage, setUserNotEnrollErrorMessage] = useState(false);
    const [permissionTopLevelError, setPermissionTopLevelError] = useState({
        isTopLevelError: false,
        isLegalEntitySelected: false,
        isDealSelected: false,
        isAssignToAllSelected: false,
        isDealAllSelected: false,
        isLegalAllSelected: false,
        isLegalEntityError: false,
        isDealError: false,
        isPermissionUpdateSuccess: false
    });
    const [apiCallError, setApiCallError] = useState({
        getProfileApiError: false,
        getCountryStateListAPIError: false,
        getProfilePermissionsforParentEINApiError: false,
        getCurrentUserRoleApiError: false
    });
    const [user, setUser] = useState(new Object());
    const [isFormInvalidError, setIsFormInvalidError] = useState(false);
    const [emailAlreadyExist, setEmailAlreadyExist] = useState(false);
    const [editableProfileRole, setEditableProfileRole] = useState(false);
    const [authUserEnrollmentStatus, setAuthUserEnrollmentStatus] = useState('');
    const [isBusinessPhoneTopLevelError, setIsBusinessPhoneTopLevelError] = useState(false);
    const [isMobilePhoneTopLevelError, setIsMobilePhoneTopLevelError] = useState(false);
    const [isMobilePhoneChanged, setIsMobilePhoneChanged] = useState(false);
    const [isUserNotListedVendor, setIsUserNotListedVendor] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [userClickedTabInMemory, setUserClickedTabInMemory] = useState('1');
    const [permissionTabModified, setPermissionTabModified] = useState(false);
    const [isFrqVendor, setIsFrqVendor] = useState(false);
    const [dataChangeConfirmationAccepted, setDataChangeConfirmationAccepted] = useState(false);
    const [userProfileDetails, setUserProfileDetails] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        profileRole: '',
        mnoBypass: false,
        phoneNumbers: {
            mobilePhone: '',
            businessPhone: ''
        },
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            country: ''
        },
        parentCompanyLegalEntityID: '',
        parentCompanyName: '',
        parentEIN: '',
    });

    useEffect(() => {
        // console.log("pclist", props.location.state.parentCompanyList)
        setParentCompanyList(props.location.state.parentCompanyList[0]);
        setParentCompanyListFull(props.location.state.parentCompanyList[1]);
        setIsAdmin(props.location.state.isAdmin);
        getCountryList();
        if (props.location.state && props.location.state.profileId) {
            getUserProfileData();
        }
        userRoleDropdownList = new UserRoleDropdownList();
        setRoleList(userRoleDropdownList.getUserDropdownList());
        setIsUserNotListedVendor(userRoleDropdownList.isUserTypeNotListedVendor());
        if(props.location?.state?.wasUserMoved){
            document.getElementById('tab-permissions').click();
        }
    }, []);

    useEffect(() => {
        transmitIframeMessage.scrollUp();
        if (userProfileSavedData) {
            setUserProfileDetails({ ...userProfileSavedData });
        }
    }, [userProfileSavedData]);

    // const defaultTab =  [
    //          {  id: 1,
    //             title: 'Personal info',
    //            icon: <span id="tab-personal-info" className = "sp-my-profile-categories" onClick={e => onTabChange(e, '1')}></span>
    //          },
    //          {
    //           id:2,
    //           title:'Permissions',
    //           icon: <span id="tab-permissions"  className = "sp-my-profile-categories" handleClick={e => onTabChange(e, '2')}></span>
    //         }
    //   ]

    const defaultTab = [

        <span id="tab-personal-info" className="sp-my-profile-categories" onClick={e => onTabChange(e, '1')}>Personal info</span>,

        <span id="tab-permissions" className="sp-my-profile-categories" onClick={e => onTabChange(e, '2')}>Permissions</span>,
        
    ]
    const appAccessTabs = [
    <span id="tab-permissions-cdc" className="sp-my-profile-categories" onClick={e => onTabChange(e, '3')}>App Access</span>
    ]

    const frqVendorTab = [
        <span id="tab-permissions" className="sp-my-profile-categories" onClick={e => onTabChange(e, '2')}>Permissions</span>
    ]


    const getUserProfileData = () => {
        LoadingHelper.inFlight();
        const graphURL = getSvcServiceURL({ service: 'graph' });
        const query = GraphQlQuery.getProfile(props.location.state.profileId);
        let frqVendorEdit = false;
        return Axios.post(graphURL, query, { cdcType: "servicing" }).then((response) => {
            LoadingHelper.outFlight();
            if (response.data.data.getProfile.parentEIN.includes('FRQ_')) {
                setIsFrqVendor(true);
                frqVendorEdit = true;
            }
            // SKK
            // if(response.data.data.getProfile.status==="Pending"){               
            //if(response.data.data.getProfile.userID===""){
            //setEditableProfileRole(true);
            //}else {

            const authProfileRoleChangeBody = {
                userID: response.data.data.getProfile.userID
            }
            Axios.post(getAuthServiceURL({ service: 'userdetails' }), authProfileRoleChangeBody, { cdcType: "auth" })
                .then(data => {
                    if (data.data.data.enrollmentDetail.enrollmentStatus) {
                        setAuthUserEnrollmentStatus(data.data.data.enrollmentDetail.enrollmentStatus);
                    }
                    if (data.data.data.enrollmentDetail.enrollmentStatus !== "complete") {
                        if (response.data.data.getProfile.status === "Pending") {
                            setEditableProfileRole(true);
                        }
                    }
                }).catch((error) => {
                    console.error("AUTH USR DETAIL CALL FAILED.", error);
                });

            if (response.data.data.getProfile.status === "Pending") {
                if (response.data.data.getProfile.userID === "") {
                    setEditableProfileRole(true);
                }
            }


            //}
            //}        

            // setFormData(response.data.data.getProfile);
            if (isSuperAdmin) {
                setParentCompanyInformation(response.data.data.getProfile.userID);
            }
            if (response.data.data.getProfile.usbRelationship === 'Unlisted_Vendor_Contributor__Contact') {
                setRoleList([userRoleDropdownList.contributorVendor]);
                setIsUserNotListedVendor(true);
                setTimeout(() => {
                    document.getElementById('usrroleunlisteduser').getElementsByTagName('input')[0].readOnly = true;
                }, 1000);

            }
            dispatch({
                type: "CREATE_PROFILE",
                payload: response.data.data.getProfile
            });
            dispatch({
                type: "SERVER_PROFILE_DATA",
                payload: response.data.data.getProfile
            });
            const parentCompanyDetails = {
                ein: response.data.data.getProfile.parentEIN,
                parentCompanyName: response.data.data.getProfile.parentCompanyName,
                parentCompanyLegalEntityID: response.data.data.getProfile.parentCompanyLegalEntityID,
                address: response.data.data.getProfile.address,
                phoneNumber: response.data.data.getProfile.phoneNumbers,
                businessProfileType: response.data.data.getProfile.businessProfileType

            }
            dispatch({
                type: "SET_PARENT_COMPANY_INFO",
                payload: parentCompanyDetails
            });
            const allPermissionURL = getDocumentmgmtServiceURL({
                service: 'getProfilePermissionsforParentEIN',
                userid: response.data.data.getProfile.parentEIN
            });
            Axios.get(allPermissionURL, { cdcType: "docmanagement",  headers:{
                // 'jwt':`${sessionStorage.getItem('tok')}`,
                // 'user-id': response.data.data.getProfile.parentEIN
            }
             }).then(permissions => {
                if (AssignPermissionHelper.isFrequentVendor(isFrequentVendor, parentCompanyDetails, response.data.data.getProfile)) {
                    dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: permissions.data
                    })
                } else {
                    const currentParentEin = response.data.data.getProfile.parentEIN !== 'Not Listed' ? response.data.data.getProfile.parentEIN : currentUser?.parentCompanyInfo?.number

                    const entitlementForCurrentEin = permissions.data.find(item => item.number === currentParentEin);

                    dispatch({
                        type: 'SET_ENTITLEMENT_SERVER_RESPONSE',
                        payload: entitlementForCurrentEin
                    })
                    setTimeout(() => {
                        if (frqVendorEdit) {
                            setActiveTab('2');
                            document.getElementById('tab-permissions').click();
                        }
                    }, 500);
                }
            }).catch((error) => {
                LoadingHelper.outFlight();
                console.error("There is an Employee Permissions Error for Update User.", error);
                setApiCallError({ getProfilePermissionsforParentEINApiError: true });
            });
        }).catch((error) => {
            LoadingHelper.outFlight();
            console.error("There is an Employee Information Error for Update User.", error);
            setApiCallError({ getProfileApiError: true });
        });
    }


    const setParentCompanyInformation = (userId) => {
        const graphURL = getSvcServiceURL({ service: 'graph' });
        const query = GraphQlQuery.getCurrentUserRole(userId);
        LoadingHelper.inFlight();
        Axios.post(graphURL, query, { cdcType: "servicing" })
            .then((res) => {
                // console.log(res);
                LoadingHelper.outFlight();
                const data = res.data.data;
                if (data.getProfileWithParentComType?.businessProfileType?.toLowerCase() === 'vendor' || data.getProfileWithParentComType?.businessProfileType?.toLowerCase() === 'specialvendor') {
                    setRoleList([this.userRoleDropdownList.siteAdminVendor, this.userRoleDropdownList.contributorVendor]);

                }
            }).catch(error => {
                LoadingHelper.outFlight();
                setApiCallError({ getCurrentUserRoleApiError: true });
            })
    }

    const getCountryList = () => {
        if (countryListData && countryListData.length > 0) {
            setCountryList(countryListData);
            return;
        }
        LoadingHelper.inFlight();
        Axios.post(getSvcServiceURL(
            { service: 'graph' }
        ), GraphQlQuery.getCountryQuery(), { cdcType: "servicing" })
            .then(res => {
                LoadingHelper.outFlight();
                const sheildFormate = res.data.data.getCountryStateList.map(item => {
                    return { value: item.countryInfo.countryCode, content: item.countryInfo.countryName }
                });
                setCountryList(sheildFormate);
                dispatch({
                    type: 'ADD_COUNTRY_LIST',
                    payload: sheildFormate
                })
            }).catch((error) => {
                LoadingHelper.outFlight();
                console.error("There is an error loading country list.", error);
                setApiCallError({ getCountryStateListAPIError: true });
            });
    }

    const onCancelClick = () => {
        let path = '/ManageUser/';
        props.history.push(path);
    }

    const onContinueClick = (userDetails, isFormValid, isFormInvalidError, usrfirstname, usrMiddleName, usrLastName, usremail,
        usrmobilephone, usrbusinessphone) => {
        // console.log("on save body", userDetails)
        if (userDetails.firstName !== usrfirstname) {
            userDetails.firstName = usrfirstname;
        }
        if (userDetails.lastName !== usrLastName) {
            userDetails.lastName = usrLastName;
        }
        if (userDetails.middleName !== usrMiddleName) {
            userDetails.middleName = usrMiddleName;
        }
        if (userDetails.email !== usremail) {
            userDetails.email = usremail;
        }
        if (userDetails.phoneNumbers.businessPhone !== usrbusinessphone) {
            userDetails.phoneNumbers.businessPhone = usrbusinessphone;
        }
        if (userDetails.phoneNumbers.mobilePhone !== usrmobilephone) {
            userDetails.phoneNumbers.mobilePhone = usrmobilephone;
        }
        let isBusinessPhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.businessPhone) ? true : false;
        let isMobilePhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.mobilePhone) ? true : false;
        resetNotification();
        if (isFormValid) {
            if (userDetails.email !== initialProfileData.email) {
                LoadingHelper.inFlight();
                dispatch(verifyEmailAlreadyExist(userDetails.email)).then(response => {
                    LoadingHelper.outFlight();
                    onContinueForward(userDetails);
                }).catch(error => {
                    LoadingHelper.outFlight();
                    if (error.emailExist) {
                        setEmailAlreadyExist(true);
                        transmitIframeMessage.scrollUp();
                    } else {
                        onContinueForward(userDetails);
                    }
                })
            } else {
                onContinueForward(userDetails);
            }

        } else {
            setUser(userDetails);
            setIsFormInvalidError(isFormInvalidError);
            setIsBusinessPhoneTopLevelError(isBusinessPhoneTopLevelError);
            setIsMobilePhoneTopLevelError(isMobilePhoneTopLevelError);
            transmitIframeMessage.scrollUp();
        }

    }

    const onSubmitPermission = (error) => {
        setPermissionTopLevelError(error);
    }

    const onContinueForward = async (userDetails) => {
        if (document.getElementById('tab_usb-tabs_0') !== null && document.getElementById('tab_usb-tabs_0') !== undefined && document.getElementById('tab_usb-tabs_0') !== '') {
            document.getElementById('tab_usb-tabs_0').style.display = 'none';
        }
        setSuccessMessage(false);
        setErrorMessage(false);
        setUserNotEnrollErrorMessage(false);
        LoadingHelper.inFlight();
        let bodyForProfileEdit = {};
        if (userDetails.profileRole !== initialProfileData.profileRole) {
            bodyForProfileEdit.profileRoleChanged = true;
            bodyForProfileEdit.previousProfileRole = initialProfileData.profileRole;
        }
        else {
            bodyForProfileEdit.profileRoleChanged = false;
        }
        bodyForProfileEdit = {
            ...bodyForProfileEdit,
            ...getEditQueryBody(userDetails, bodyForProfileEdit.profileRoleChanged)
        };

        /* THIS IS DONE REMOVING BECUSE CHANGIN THE UNLISTEDD VENDOR NAME AFTER ITS CREATED INITLALY MAY BE CONFUSING FOR USER
        if(initialProfileData?.parentEIN==='Not Listed'){
            bodyForProfileEdit.data.parentCompanyName=userDetails.parentCompanyName;
        }*/

        let emailChanged = false;
        let entitlementsUpdate = {
            url: '',
            body: null
        };
        if (bodyForProfileEdit.data.email != initialProfileData.email) {
            emailChanged = true;
            entitlementsUpdate.url = getDocumentmgmtServiceURL({
                service: 'updateProfilePermission',
                userid: `${initialProfileData.userID}`
            });
            entitlementsUpdate.body = {
                "profileId": initialProfileData.profileID,
                "source": "CDC",
                "emailId": bodyForProfileEdit.data.email
            }
        }
        try {
            // LDAP role update when the profile role changed.
            if (bodyForProfileEdit.profileRoleChanged) {
                // LoadingHelper.inFlight();
                const authProfileRoleChangeBody = {
                    userID: initialProfileData.userID,
                    profileRole: userDetails.profileRole
                }

                await Axios.post(getAuthServiceURL({ service: 'updateRole' }), authProfileRoleChangeBody, { cdcType: "auth_non_cdc_user" });


            }
            bodyForProfileEdit.data.shouldUpdateEmailInAuthDB = emailChanged;
            bodyForProfileEdit.data.shouldUpdateMoblileNumberInAuthDB = isMobilePhoneChanged;
            if (bodyForProfileEdit.previousProfileRole && bodyForProfileEdit.profileRoleChanged) {
                bodyForProfileEdit.data.previousProfileRole = bodyForProfileEdit.previousProfileRole;
                bodyForProfileEdit.data.profileRoleChanged = bodyForProfileEdit.profileRoleChanged;
            }

            if (editableProfileRole && userDetails.mnoBypass) {
                bodyForProfileEdit.data.phoneNumbers.mobilePhone = "";
                bodyForProfileEdit.data.phoneNumbers.businessPhone = "";
            }
            console.log("going to hit api call");
            const profileUpdate = await Axios.post(getSvcOldServiceURL({ service: 'update' }), bodyForProfileEdit, { cdcType: "servicing_Update" });
            dispatch({
                type: "SERVER_PROFILE_DATA",
                payload: profileUpdate.data
            });
            dispatch({
                type: "CREATE_PROFILE",
                payload: profileUpdate.data
            });
            //------------CORE TEAM EMAIL UPDATE START 
            if (emailChanged) {
                await Axios.put(entitlementsUpdate.url, entitlementsUpdate.body, { cdcType: "docmanagement" });
                //------------CORE TEAM EMAIL UPDATE ENDS 
            }

            if (isMobilePhoneChanged) {
                console.log("hitting phone number change API");

                    let phoneNumberUpdate = {
                        url: '',
                        body: null
                    };
           
                  

                    phoneNumberUpdate.url = getDocumentmgmtServiceURL({
                        service: 'updatePhoneNumber',
                        userid: `${initialProfileData.userID}`
                    });
                  
                    phoneNumberUpdate.body = {
                        "userID": initialProfileData.userID,
                        "email": bodyForProfileEdit.data.email,
                        "phone": {
                            "phoneNumber": userDetails.phoneNumbers.mobilePhone
                        },
                        "updateProfileDB": false
                    }

                    await Axios.put(phoneNumberUpdate.url, phoneNumberUpdate.body, { cdcType: "docmanagement" });
                //------------CORE TEAM EMAIL UPDATE ENDS 
            }
               

            await Axios.put(getDocumentmgmtServiceURL({ service: 'updateProfilePermission', userid: `${initialProfileData.userID}` }),
                updateDocManagementBody(userDetails, bodyForProfileEdit.profileRoleChanged), { cdcType: "docmanagement" });
            LoadingHelper.outFlight();
            if (userProfileRef.current !== undefined)
                userProfileRef.current.clearFormChange();
            setSuccessMessage(true);
            transmitIframeMessage.scrollUp();

        } catch (error) {
            LoadingHelper.outFlight();
            if (error.response !== undefined) {
                if (error.response.data.error !== undefined && error.response.data.error.code !== undefined && error.response.data.error.code === '0375.3452');
                setUserNotEnrollErrorMessage(true);
            }
            setErrorMessage(true)
            transmitIframeMessage.scrollUp();
        }
        if (document.getElementById('tab_usb-tabs_0') !== null && document.getElementById('tab_usb-tabs_0') !== undefined && document.getElementById('tab_usb-tabs_0') !== '') {
            document.getElementById('tab_usb-tabs_0').style.display = 'block';
        }
    }

    const updateDocManagementBody = (userDetails, profileRoleChanged) => {
        // console.log("doc mngmt body", userDetails)
        const initialData = {
            "profileId": initialProfileData.profileID,
            "source": "CDC",
            "userId": initialProfileData.userID,
            "emailId": userDetails.email,
            "vendorRelationship": [
                {
                    "vendorName": userDetails.parentCompanyName,
                    "vendorNumber": userDetails.parentEIN,
                    "vendorLegalEntityID": userDetails.parentCompanyLegalEntityID,
                    "vendorRole": userDetails.profileRole
                }
            ],
            "permissions": [
                {
                    "name": "CDC",
                    "parentEntitys": []
                    // "parentEntitys": Array.isArray(entitlementServerData) ? entitlementServerData.map(entitlement => {
                    //     entitlement.role = userDetails.profileRole;
                    //     return entitlement;
                    // }) : [{ ...entitlementServerData, "role": userDetails.profileRole }]
                }
            ],
            "firstName": userDetails.firstName,
            "lastName": userDetails.lastName,
            "middleName": userDetails.middleName,
            "isMobilePhoneChanged":isMobilePhoneChanged,
            "mobileNumber": [
                userDetails.phoneNumbers.mobilePhone
                // userDetails.phoneNumbers.mobilePhone //TODO : need to ask rahul if he need business phone of a company
            ],
        };
        if (profileRoleChanged && entitlementServerData) {

            if (AssignPermissionHelper.isFrequentVendor(isFrequentVendor, null, initialProfileData)) {
                initialData.vendorRelationship = [{
                    "vendorName": initialProfileData.parentCompanyName,
                    "vendorNumber": initialProfileData.parentEIN,
                    "vendorRole": userDetails.profileRole,
                    "vendorLegalEntityID": initialProfileData.parentCompanyLegalEntityID
                }]
            } else if (initialProfileData?.usbRelationship === 'Unlisted_Vendor_Contributor__Contact') {
                initialData.vendorRelationship = [{
                    "vendorProfileId": initialProfileData.profileID,
                    "vendorName": `${initialProfileData.firstName} ${initialProfileData.lastName}`, //Rahul mentioned to send firstName and lastName for unlisted vendor
                    "vendorNumber": initialProfileData.ein,
                    "vendorRole": userDetails.profileRole,
                    "vendorLegalEntityID": initialProfileData.parentCompanyLegalEntityID
                }]
            }

            initialData.permissions = [
                {
                    "name": "CDC",
                    "parentEntitys": Array.isArray(entitlementServerData) ? entitlementServerData.map(entitlement => {
                        entitlement.role = userDetails.profileRole;
                        return entitlement;
                    }) : [{ ...entitlementServerData, "role": userDetails.profileRole }]
                }
            ]
            // C071993 modified this to fix contri to site admin permission issue
            for (let i = 0; i < initialData.permissions.length; i++) {
                let item = initialData.permissions[0].parentEntitys;
                for (let j = 0; j < item.length; j++) {
                    delete item[j].deals;
                    delete item[j].reportingEntitys;
                    delete item[j].categories;
                }
            }
        }

        return initialData;
    }

    const getEditQueryBody = (userDetails, profileRoleChanged) => {
        // console.log("edit query body", userDetails)
        return {
            "requestType": {
                "serviceType": "CDC_PROF_MAINT",
                "serviceSubType": "EDIT_PROFILE"
            },
            "data": {
                "intent": "EDIT_PROFILE",
                "profileID": initialProfileData.profileID,
                "userID": initialProfileData.userID,
                "firstName": userDetails.firstName,
                "lastName": userDetails.lastName,
                "middleName": userDetails.middleName,
                "email": userDetails.email,
                "phoneNumbers": {
                    "mobilePhone": userDetails.phoneNumbers.mobilePhone,
                    "businessPhone": userDetails.phoneNumbers.businessPhone
                },
                "mnoBypass": editableProfileRole ? userDetails.mnoBypass : false,
                "authUserEnrollmentStatus": authUserEnrollmentStatus,
                "address": {
                    "addressLine1": userDetails.address.addressLine1,
                    "addressLine2": userDetails.address.addressLine2,
                    "city": userDetails.address.city,
                    "state": userDetails.address.state,
                    "country": userDetails.address.country,
                    "zipCode": userDetails.address.zipCode
                },
                "parentEIN": userDetails.parentEIN,
                "parentCompanyName": userDetails.parentCompanyName,
                "parentCompanyLegalEntityID": userDetails.parentCompanyLegalEntityID,
                "profileRole": userDetails.profileRole,
                "status": initialProfileData.status,
                "usbRelationship": getUsbRelationShip(userDetails, profileRoleChanged),
                "lastEditedBy": operatingUser,
                "sourceType": "CDC"
            }
        }
    }
    const getUsbRelationShip = (userDetails, profileRoleChanged) => {
        if (profileRoleChanged && userDetails.profileRole === 'Contributor') {
            return initialProfileData.usbRelationship.replace('Site_Administrator', 'Contributor');
        } else if (profileRoleChanged && userDetails.profileRole.toLowerCase() === 'site administrator') {
            return initialProfileData.usbRelationship.replace('Contributor', 'Site_Administrator');
        }
        return initialProfileData.usbRelationship;
    }
    const resetNotification = () => {
        setSuccessMessage(false);
        setErrorMessage(false);
        setEmailAlreadyExist(false);
        setPermissionTopLevelError({ isPermissionUpdateSuccess: false });
        setIsFormInvalidError(false);
        setApiCallError({ getProfileApiError: false, getCountryStateListAPIError: false, getProfilePermissionsforParentEINApiError: false, getCurrentUserRoleApiError: false });
    }

    const onProfileUpdate = (data) => {
        console.log();
        setUserProfileDetails(data);
    }

    const onChangeText = (userDetails: UserProfileFormModel) => {

        if (emailAlreadyExist && userProfileDetails.email !== user.email) {
            setEmailAlreadyExist(false);
        }
        //setUser(userDetails);
    }

    const onPhoneNumberChange = (userDetails: UserProfileFormModel) => {
        let isBusinessPhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.businessPhone) ? true : false;
        let isMobilePhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.mobilePhone) ? true : false;
        // setIsBusinessPhoneTopLevelError(isBusinessPhoneTopLevelError);
        // setIsMobilePhoneTopLevelError(isMobilePhoneTopLevelError);
        //setIsFormInvalidError(false);
        setIsMobilePhoneChanged(true);
    }

    const onJumpLinkClick = () => {
        setTimeout(() => {
            let str = window.location.hash;
            handleFieldFocus(str);
        }, 300);

    }


    const handleFieldFocus = (type: string): void => {
        switch (type) {
            case "#first-name":
                document.getElementById('usrfirstname').getElementsByTagName('input')[0].focus();
                break;
            case "#last-name":
                document.getElementById('usrlastname').getElementsByTagName('input')[0].focus();
                break;
            case "#usrRole":
                document.getElementById('role-select-id').focus();
                break;
            case "#email-address":
                document.getElementById('usremail').getElementsByTagName('input')[0].focus();
                break;
            case "#mobile-number":
                document.getElementById('usrmobilephone').getElementsByTagName('input')[0].focus();
                break;
            case "#option":
                document.getElementById('radioOption1').focus();
                break;
            case "#legal-entities":
                document.getElementById('allEntity').focus();
                break;
            case "#entity-documents":
                document.getElementById('entityDocAll').focus();
                break;
            case "#deals":
                document.getElementById('allDeals').focus();
                break;
            case "#deal-documents":
                document.getElementById('dealDocAll').focus();
                break;
            default:
                break;
        }
    }

    const onTabChange = (e, tabIndex) => {
        if (activeTab == tabIndex || !e.isTrusted) {
            return;
        }
        resetNotification();
        let profileFormChanged = false;
        profileFormChanged = props.isProfileFormChanged;

        if (profileFormChanged === false) {
            profileFormChanged = FormValidationHelpers.profileFormDataChanged(userProfileDetails);
        }
        if (dataChangeConfirmationAccepted == true) {
            profileFormChanged = false;
        }

        if (activeTab == '1' && profileFormChanged) {
            // TODO if tru        

            e.stopPropagation();
            setOpenConfirmationDialog(true);
            setUserClickedTabInMemory(tabIndex);
        } else if (activeTab == '2' && permissionTabModified) {
            e.stopPropagation()
            setOpenConfirmationDialog(true);
            setUserClickedTabInMemory(tabIndex);
        } else {
            setActiveTab(tabIndex);
        }
    }

    const dialogContinueForward = () => {
        setOpenConfirmationDialog(false);
        setDataChangeConfirmationAccepted(true);
        setActiveTab(userClickedTabInMemory);
        // moving to permissions tab.
        if (userClickedTabInMemory == '2') {
            setActiveTab(userClickedTabInMemory)
            setUserProfileDetails(initialProfileData);
            document.getElementById('tab-permissions').click();
        }
        if (userClickedTabInMemory == '1') {
            setActiveTab(userClickedTabInMemory)
            document.getElementById('tab-personal-info').click();
        }
    }

    const dialogCancel = () => {
        setOpenConfirmationDialog(false);
    }

    const onPermissionsModified = (modified) => {
        setPermissionTabModified(modified);
    }


    return (
        <FeatureFlagsContext.Consumer>
        {(flags)=>
        <React.Fragment>
            <ConfirmationDialog open={openConfirmationDialog} title={'Continue without saving?'} multiLineBody={[]} body={activeTab == '1' ? 'You’ll lose any changes you made to this user’s personal info.' : 'You’ll lose any changes you made to this user’s permissions.'}
                toggle={dialogCancel} primaryButtonText={'Continue'} primaryClickHandler={dialogContinueForward} />

            <div className="sp-mu-title sp-mr-b-16 " >
                <div className="sp-page-title">Edit user profile</div>
            </div>

            <USBGrid >
                <USBColumn largeSpan={16}>
                    {
                        permissionTopLevelError.isPermissionUpdateSuccess ?
                            <USBNotification
                                addClasses="sp-width-100 sp-mr-b-16"
                                variant="confirmation"
                                handleClose={resetNotification}
                                roleType="alert"
                                notificationData={[{
                                    text: "You’ve updated the user’s permissions."
                                }]}
                            />
                            : null}
                    {permissionTopLevelError.isPermissionUpdateFailed && activeTab === '2' ?
                        <USBNotification
                            addClasses="sp-width-100 sp-mr-b-16"
                            variant="error"
                            roleType="alert"
                            handleClose={resetNotification}
                            notificationData={[{
                                text: "There was a problem saving the changes to this user’s profile. Please try again."
                            }]}
                        />
                        : null}
                    {apiCallError.getProfileApiError || apiCallError.getCountryStateListAPIError
                        || apiCallError.getProfilePermissionsforParentEINApiError ?
                        <USBNotification
                            addClasses="sp-width-100 sp-mr-b-16"
                            variant="error"
                            roleType="alert"
                            handleClose={resetNotification}
                            notificationData={[{
                                text: "We’re having trouble accessing the user’s information. Please refresh your browser and try again."
                            }]}
                        />
                        : null}

                    {successMessage ?
                        <USBNotification
                            addClasses="sp-width-100 sp-mr-b-16"
                            variant="confirmation"
                            roleType="alert"
                            handleClose={resetNotification}
                            notificationData={[{
                                text: "You’ve updated the user’s profile."
                            }
                            ]}
                        />
                        : null}
                    {
                        errorMessage && !userNotEnrollErrorMessage ?
                            <USBNotification
                                addClasses="sp-width-100 sp-mr-b-16"
                                variant="error"
                                handleClose={resetNotification}
                                roleType="alert"
                                notificationData={[{
                                    text: "There was a problem saving the changes to this user’s profile. Please try again."
                                }
                                ]}
                            />
                            : null
                    }
                    {
                        errorMessage && userNotEnrollErrorMessage ?
                            <USBNotification
                                addClasses="sp-width-100 sp-mr-b-16"
                                variant="error"
                                handleClose={resetNotification}
                                roleType="alert"
                                notificationData={[{
                                    text: "You can’t change the user’s role until they finish enrolling. Please try again later."
                                }
                                ]}
                            />
                            : null
                    }
                    {/* User Permission */}
                    {permissionTopLevelError.isTopLevelError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification
                                addClasses="sp-mr-b-12"
                                variant="error"
                                roleType="alert"
                                notificationData={[{
                                    text: 'Select how you want to',
                                    linkText: 'assign permissions.',
                                    link: '#option'
                                }
                                ]} />
                        </div> : null}
                    {permissionTopLevelError.isLegalEntitySelected && permissionTopLevelError.isLegalEntityError && !permissionTopLevelError.isLegalEntityFieldError && permissionTopLevelError.isLegalEntityDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert'
                                notificationData={[{
                                    text: 'Select your',
                                    linkText: 'legal entities.',
                                    link: '#legal-entities'
                                }
                                ]} /></div> : null}
                    {permissionTopLevelError.isLegalEntitySelected && permissionTopLevelError.isLegalEntityError && permissionTopLevelError.isLegalEntityFieldError && !permissionTopLevelError.isLegalEntityDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Select your',
                                linkText: 'document types.',
                                link: '#entity-documents'
                            }
                            ]} /> </div> : null}
                    {permissionTopLevelError.isLegalEntitySelected && permissionTopLevelError.isLegalEntityError && !permissionTopLevelError.isLegalEntityFieldError && !permissionTopLevelError.isLegalEntityDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Select your',
                                linkText: 'legal entities.',
                                link: '#legal-entities'
                            }, {
                                text: 'Select your',
                                linkText: 'document types.',
                                link: '#entity-documents'
                            }
                            ]} /></div> : null}
                    {permissionTopLevelError.isDealSelected && permissionTopLevelError.isDealError && !permissionTopLevelError.isDealFieldError && permissionTopLevelError.isDealDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Select your',
                                linkText: 'deals.',
                                link: '#deals'
                            }
                            ]} /></div> : null}
                    {permissionTopLevelError.isDealSelected && permissionTopLevelError.isDealError && permissionTopLevelError.isDealFieldError && !permissionTopLevelError.isDealDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Select your',
                                linkText: 'document types.',
                                link: '#deal-documents'
                            }
                            ]} /></div> : null}
                    {permissionTopLevelError.isDealSelected && permissionTopLevelError.isDealError && !permissionTopLevelError.isDealFieldError && !permissionTopLevelError.isDealDocFieldError ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Select your',
                                linkText: 'deals.',
                                link: '#deals'
                            }, {
                                text: 'Select your',
                                linkText: 'document types.',
                                link: '#deal-documents'
                            }
                            ]} /></div> : null}



                    {/* User Info */}
                    {isFormInvalidError && isMobilePhoneTopLevelError && user.firstName !== '' && user.lastName !== '' && user.profileRole !== '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter a valid',
                                    linkText: 'mobile phone number.',
                                    link: '#mobile-number'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName === '' && user.profileRole === '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Enter the user’s',
                                linkText: 'first name.',
                                link: '#first-name'
                            }, {
                                text: 'Enter the user’s',
                                linkText: 'last name.',
                                link: '#last-name'
                            }, {
                                text: 'Select the user’s',
                                linkText: 'role type.',
                                link: '#usrRole'
                            }, {
                                text: 'Enter a valid',
                                linkText: 'email address.',
                                link: '#email-address'
                            }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName === '' && user.profileRole === '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Enter the user’s',
                                linkText: 'last name.',
                                link: '#last-name'
                            }, {
                                text: 'Select the user’s',
                                linkText: 'role type.',
                                link: '#usrRole'
                            }, {
                                text: 'Enter a valid',
                                linkText: 'email address.',
                                link: '#email-address'
                            }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName !== '' && user.profileRole === '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick}>
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Enter the user’s',
                                linkText: 'first name.',
                                link: '#first-name'
                            }, {
                                text: 'Select the user’s',
                                linkText: 'role type.',
                                link: '#usrRole'
                            }, {
                                text: 'Enter a valid',
                                linkText: 'email address.',
                                link: '#email-address'
                            }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName === '' && user.profileRole !== '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Enter the user’s',
                                linkText: 'first name.',
                                link: '#first-name'
                            }, {
                                text: 'Enter the user’s',
                                linkText: 'last name.',
                                link: '#last-name'
                            }, {
                                text: 'Enter a valid',
                                linkText: 'email address.',
                                link: '#email-address'
                            }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName === '' && user.profileRole == '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                text: 'Enter the user’s',
                                linkText: 'first name.',
                                link: '#first-name'
                            }, {
                                text: 'Enter the user’s',
                                linkText: 'last name.',
                                link: '#last-name'
                            }, {
                                text: 'Select the user’s',
                                linkText: 'role type.',
                                link: '#usrRole'
                            }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName !== '' && user.profileRole === '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Select the user’s',
                                    linkText: 'role type.',
                                    link: '#usrRole'
                                }, {
                                    text: 'Enter a valid',
                                    linkText: 'email address.',
                                    link: '#email-address'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName === '' && user.profileRole !== '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'first name.',
                                    link: '#first-name'
                                }, {
                                    text: 'Enter the user’s',
                                    linkText: 'last name.',
                                    link: '#last-name'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName !== '' && user.profileRole !== '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'first name.',
                                    link: '#first-name'
                                }, {
                                    text: 'Enter a valid',
                                    linkText: 'email address.',
                                    link: '#email-address'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName === '' && user.profileRole !== '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'last name.',
                                    link: '#last-name'
                                }, {
                                    text: 'Enter a valid',
                                    linkText: 'email address.',
                                    link: '#email-address'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName === '' && user.profileRole === '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'last name.',
                                    link: '#last-name'
                                }, {
                                    text: 'Select the user’s',
                                    linkText: 'role type.',
                                    link: '#usrRole'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName !== '' && user.profileRole === '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'first name.',
                                    link: '#first-name'
                                }, {
                                    text: 'Select the user’s',
                                    linkText: 'role type.',
                                    link: '#usrRole'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName !== '' && user.profileRole !== '' && user.email === '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter a valid',
                                    linkText: 'email address.',
                                    link: '#email-address'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName !== '' && user.profileRole === '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Select the user’s',
                                    linkText: 'role type.',
                                    link: '#usrRole'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName !== '' && user.lastName === '' && user.profileRole !== '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'last name.',
                                    link: '#last-name'
                                }
                            ]} /></div>
                        : null}
                    {isFormInvalidError && user.firstName === '' && user.lastName !== '' && user.profileRole !== '' && user.email !== '' ?
                        <div className="sp-width-100" onClick={onJumpLinkClick} >
                            <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                {
                                    text: 'Enter the user’s',
                                    linkText: 'first name.',
                                    link: '#first-name'
                                }
                            ]} /></div>
                        : null}
                    {
                        emailAlreadyExist ?
                            <div className="sp-width-100" onClick={onJumpLinkClick} >
                                <USBNotification variant="error" roleType='alert' notificationData={[
                                    {
                                        linkText: 'Email Address',
                                        text: ' already exists.',
                                        link: '#email-address'
                                    }
                                ]} />
                            </div> : null
                    }
                </USBColumn>
            </USBGrid>

            <div className="sp-row sp-justify-content sp-content-display sp-mr-b-16">
                {!isFrqVendor ?
                    <div className='sp-col-7'>
                        Review your user's profile information. To make an update, go to the <strong> Personal info </strong> tab.
                        <br></br>
                        <br></br>
                        To change their reporting requirement permissions, go to the <strong> Permissions </strong> tab. You can give access to all requirements or assign by legal entity or deal.
                    </div>
                    :
                    <div className='sp-col-7'>
                        To change their reporting requirement permissions, go to the <strong> Permissions </strong> tab. You can give access to all requirements or assign by legal entity or deal.
                    </div>
                }
            </div>

            <USBGrid>
                <USBColumn largeSpan={16}>
                    {!isFrqVendor ? <USBTabs tabs={
                        flags?.featureDetails.includes("feature_app_access")?[...defaultTab,...appAccessTabs]:defaultTab} addClasses="sp-width-100">


                        {/* {
                        activeTab == 1 && */}
                        <div className="sp-row sp-mr-b-24 sp-col-6">
                            <UserInformationForm
                                ref={userProfileRef}
                                onCancelHandler={onCancelClick}
                                onSubmitHandler={onContinueClick}
                                countryList={countryList}
                                parentList={parentCompanyList}
                                isAdmin={isAdmin}
                                parentListFull={parentCompanyListFull}
                                roleList={roleList}
                                primaryButtonText='Save changes'
                                isUserTypeNotListedVendor={isUserNotListedVendor}
                                readOnlyFields={['companyName']}
                                formData={userProfileDetails}
                                editableProfileRole={editableProfileRole}
                                emailAlreadyExist={emailAlreadyExist}
                                onChangeTextHandler={onChangeText}
                                handlePhoneNumberChangeHandler={onPhoneNumberChange}
                                type="EDITPROFILE"
                                flags={flags}
                            />
                        </div>
                        {/* } */}

                        {initialProfileData?.profileID && userProfileSavedData?.profileRole &&
                            // activeTab == 2 && 
                            <div>
                                <AssignPermissions location={props.location}
                                    createView={false} onSubmitHandler={onSubmitPermission} onModified={onPermissionsModified} />
                            </div>
                        }
                            {/* Karthik If feature_app_access is enabled */}
                            {
                            initialProfileData?.profileID && userProfileSavedData?.profileRole?.includes('Contributor') ?
                                <div>
                                   <AssignPermissionsCDC edit={true} initialProfileData={initialProfileData}/>
                                </div>:
                                
                                <div className="sp-col-6">The user has access to all functionalities.
                                <br></br>
                                <div className="sp-caption">
                                    To assign specific access, go to the <strong>Personal info</strong> tab.  Then select <strong>Contributor</strong> from the <strong>Role</strong> dropdown menu.
                                </div>
                            </div>
                          
                                
                            }


                    </USBTabs>
                        :
                        <USBTabs tabs={frqVendorTab} addClasses="sp-width-100">
                            {initialProfileData?.profileID && userProfileSavedData?.profileRole &&
                                <div>
                                    <AssignPermissions location={props.location}
                                        createView={false} onSubmitHandler={onSubmitPermission} onModified={onPermissionsModified} />
                                </div>
                            }

                        </USBTabs>}



                       
                        
                </USBColumn>
            </USBGrid>
        </React.Fragment>
      }
         </FeatureFlagsContext.Consumer>
    )
}

const mapStateToProps = state => {
    return {
        isProfileFormChanged: state.userProfileInformation.isProfileFormChanged
    };
};

export default connect(mapStateToProps)(EditUserProfile);