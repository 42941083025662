/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PlatformController from '../RequirementDetails/PlatformController';

// import '../../../node_modules/@usb-edm/react-document-upload/dist/library/styles/scss/index.scss';
import {
  EDMDocumentUpload,
  UploadView,
  defaultContent,
} from '@usb-edm/react-document-upload';

import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from 'react';
// import Router from 'next/router'
// import { useRouter } from 'next/router'
import { useHistory, Link } from 'react-router-dom';
import dateAlert from '../../../assets/img/dateAlert.png';
import {
  USBGrid,
  USBColumn,
  USBButton,
  USBModal,
  USBModalHeader,
  USBModalBody,
  USBModalFooter,
  USBModalCloseIcon,
} from '@usb-shield/components-react';
import USBBreadcrumb from '@usb-shield/components-react/dist/cjs/breadcrumb';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { TabView, TabPanel } from 'primereact/tabview';
import callApi from '../../components/utilities/callApi';
import Loader from '../../components/utilities/Loader';
import { getQS, saveAsFile } from '../../components/utilities/Utils';
import Messages from '../../components/organisms/Messages';
import { endpoints } from '../../../config/connections';
import DocumentList from './DocumentList';
import {ExportToExcel} from '../../components/utilities/ExportToExcel';
import { DocContext } from '../context/DocContext';
import './requirementdetails.css';
import { USBIconShow, USBIconDownload } from '@usb-shield/react-icons';
import useUserRole from '../../components/Hooks/use-UserRole'
import useWarningMessage from './useWarningMessage';

const addAriaLabel = () => {
  const searchElement = document.getElementsByClassName('p-checkbox-box');
  const dropElement = document.getElementsByClassName('p-dropdown-trigger');
  for (let i = 0; i < searchElement.length; i += 1) {
    searchElement[i].setAttribute('aria-label', 'Mark as final');
  }
  for (let j = 0; j < dropElement.length; j += 1) {
    dropElement[j].setAttribute('aria-label', 'Click to show more');
  }
};

export const getDocumentListingNoAPI = (setReportingDetailsData, newData) => {
  setReportingDetailsData(newData);
  addAriaLabel();
};

export const convert24HoursDate = (d) => {
  var dd;
  if (d.slice(-5) === "PM CT") {
    var hrs = parseInt(d.slice(-10, -8))
    var mins = d.slice(-8, -6)
    hrs = hrs + 12
    dd = d.slice(0, 10) + " " + hrs + ":" + mins;
  } else if (d.slice(-5) === "AM CT") {
    dd = (d.slice(0, 16));
  } else {
    console.log("Received Unrecognized Formatted String");
  }
  return (new Date(dd));
}
export const getDocumentListing = (setReportingDetailsData) => {
  const listingId = getQS('id');
  let refreshRequired = false;
  const listUrl = `${endpoints.api}/document-management/file-download/v1/documents/${listingId}/files`;
  callApi
    .getApiDataRequirement(listUrl)
    .then((response) => {
      const fullData = [];
      response.data.sort(function (a, b) {
        var dateA = convert24HoursDate(a.documentMetadata[0].uploadDate);
        var dateB = convert24HoursDate(b.documentMetadata[0].uploadDate);
        return dateA < dateB ? 1 : -1;
      });


      response.data.forEach((item) => {
        try {
          const data = {};
          data.status = 'pending';
          data.parentId = item.parentfileNetId;
          data.id = item.documentMetadata[0].fileNetMetadata.id ? item.documentMetadata[0].fileNetMetadata.id : item.documentMetadata[0].filenetId;
          data.versions = [];
          data.uploadedByName = item.documentMetadata[0].uploadedby || '-';
          data.legalHoldFlag = item.documentMetadata[0].legalRecordsHold;
          data.checked = false;
          if (item.documentMetadata[0]?.uploadDate != null) {
            const dateandTime = item.documentMetadata[0]?.uploadDate?.split(' ');
            const date = dateandTime[0];
            const time = dateandTime.slice(1).join(' ');
            data.uploadedDate = date;
            data.uploadedTime = time;
          }
          else {
            data.uploadedDate = "-";
            data.uploadedTime = "";
          }

          // const dateField = item.documentMetadata[0].uploadDate.split('T')
          // const temp = dateField[0].replace(/-/gi, '/')
          // data.uploadedDate = moment(temp, 'YYYY/MM/DD').format('MM/DD/YYYY')
          data.printfilename = item.documentMetadata[0].documentName;
          if (item.documentMetadata[0].documentName.length > 40) {
            data.name = `${item.documentMetadata[0].documentName.substring(
              0,
              35
            )}...`;
            data.fullName = item.documentMetadata[0].documentName;
          } else {
            data.name = item.documentMetadata[0].documentName;
            data.fullName = '';
          }
          if (item.documentMetadata[0].fileNetMetadata !== null) {
            data.size = item.documentMetadata[0].fileNetMetadata.fileSize;
            data.title = item.documentMetadata[0].fileNetMetadata.title;
            data.uploadedByMail =
              item.documentMetadata[0].fileNetMetadata.email;
            data.MarkedasFinalValue =
              item.documentMetadata[0].fileNetMetadata.markedAsFinal;
            data.checked = false;
            data.renamedFlag =
              item.documentMetadata[0].fileNetMetadata.isRenamed;
            data.permanentLocationFlag =
              item.documentMetadata[0].fileNetMetadata.movedtoPermanentLocation;
            data.edmStatus = item.documentMetadata[0].fileNetMetadata.edmStatus;

            if (
              item.documentMetadata[0].fileNetMetadata.edmStatus == 'SentToEDM' || item.documentMetadata[0].fileNetMetadata.edmStatus == 'SentToEdmTemp'
            ) {
              refreshRequired = true;
            }
            data.downloadId = item.documentMetadata[0].fileNetMetadata.id;
          }
          for (let i = 1; i < item.documentMetadata.length; i += 1) {
            try {
              const subData = {};
              subData.status = 'pending';
              subData.id = item.documentMetadata[i].fileNetMetadata.id ? item.documentMetadata[i].fileNetMetadata.id : item.documentMetadata[i].filenetId;
              subData.versions = [];
              subData.uploadedByName =
                item.documentMetadata[i].uploadedby || '-';
              subData.legalHoldFlag = item.documentMetadata[i].legalRecordsHold;
              subData.checked = false;
              const subDateandTime =
                item.documentMetadata[i]?.uploadDate?.split(' ') || "01/01/2010 00:00 AM CT";
              const subDate = subDateandTime[0];
              const subTime = subDateandTime.slice(1).join(' ');
              subData.uploadedDate = subDate;
              subData.uploadedTime = subTime;
              if (item.documentMetadata[i].documentName.length > 40) {
                subData.name = `${item.documentMetadata[
                  i
                ].documentName.substring(0, 35)}...`;
                subData.fullName = item.documentMetadata[i].documentName;
              } else {
                subData.name = item.documentMetadata[i].documentName;
                subData.fullName = '';
              }
              if (item.documentMetadata[i].fileNetMetadata !== null) {
                subData.size =
                  item.documentMetadata[i].fileNetMetadata.fileSize;
                subData.title =
                  item.documentMetadata[i].fileNetMetadata.title;
                subData.uploadedByMail =
                  item.documentMetadata[i].fileNetMetadata.email;
                subData.MarkedasFinalValue =
                  item.documentMetadata[i].fileNetMetadata.markedAsFinal;
                subData.checked = false;
                subData.renamedFlag =
                  item.documentMetadata[i].fileNetMetadata.isRenamed;
                subData.permanentLocationFlag =
                  item.documentMetadata[
                    i
                  ].fileNetMetadata.movedtoPermanentLocation;
                subData.downloadId =
                  item.documentMetadata[i].fileNetMetadata.id;
                subData.edmStatus =
                  item.documentMetadata[i].fileNetMetadata.edmStatus;

                if (
                  item.documentMetadata[i].fileNetMetadata.edmStatus == 'SentToEDM' || item.documentMetadata[i].fileNetMetadata.edmStatus == 'SentToEdmTemp'
                ) {
                  refreshRequired = true;
                }
                subData.version = item.documentMetadata[i].fileNetMetadata.version;
              }
              data.versions.push(subData);
            } catch (error) {
              // console.log(error);
            }
          }
          fullData.push(data);
        } catch (error) {
          // console.log(error);
        }
      });
      setReportingDetailsData(fullData);
      // console.log('refreshRequired'+refreshRequired);
      if (refreshRequired) {
        // console.log('Refreshing again as EDM status is in progress');
        setTimeout(() => {
          getDocumentListing(setReportingDetailsData);
        }, 5000);
      }


      addAriaLabel();
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const getDocDetails = (
  setRequirementResults,
  setLoading,
  setDocTemplateFlag
) => {
  // setDocFilenetId
  setLoading(true);
  const apiURL = `${endpoints.apigee
    }/document-management/cdc/requirements/v1/documents/${getQS('id')}/details`;
  callApi
    .getApiDataRequirement(apiURL)
    .then((result) => {
      setRequirementResults(result.data);
      if (result.data.docTemplateFlg === 'true') {
        setDocTemplateFlag(true);
      } else {
        setDocTemplateFlag(false);
      }
      // setDocFilenetId(result.data.docFilenetId)
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};

export const submitforReview = (
  setLoading,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setRequirementResults,
  setDocTemplateFlag,
  setUploadedFlag,
  setSavedFlag,
  setIsDirty,
  removeWarning
) => {
  // setDocFilenetId
  setSuccessFlag(false);
  setFailedFlag(false);
  const listingId = getQS('id');
  setLoading(true);
  const data = { test: 'test' };
  const url = `${endpoints.apigee}/document-management/cdc/requirements/v1/documents-details/${listingId}`;
  callApi
    .postApi(url, data)
    .then((response) => {
      if (response.status === 204) {
        setSuccessFlag(true);
        setSavedFlag(false);
        setUploadedFlag(false);
        // setIsDirty(false);
        // setSubmitFlag(true)
        removeWarning()
        setMessageContent('Successfully submitted documents for review.');
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          setSuccessFlag(false);
        }, 5000);
        getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag); // setDocFilenetId
      }
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};

export const duedateTemplate = (requirementResults) => {
  const docdueDate = moment(requirementResults.dueDate, 'YYYYMMDD').format(
    'YYYYMMDD'
  );
  let dateHtml;
  let dueHtml = '';
  const today = moment(new Date(), 'YYYYMMDD').format('YYYYMMDD');
  if (requirementResults.dueDate != null) {
    const days = moment(docdueDate, 'YYYYMMDD').diff(
      moment(today, 'YYYYMMDD'),
      'days'
    );
    if (days < 0 && requirementResults.status === 'Not Submitted') {
      dueHtml = (
        <span className="font-red date-details">
          <img className="warn-img" src={dateAlert} alt="warning" />
          &nbsp;Past due
        </span>
      );
    }

    dateHtml = (
      <span>
        {moment(docdueDate).format('MMM DD, YYYY')}
        <span>{dueHtml}</span>
      </span>
    );
  } else dateHtml = '';
  return dateHtml;
};

export const saveFiles = (
  reportingDetailsData,
  setLoading,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setReportingDetailsData,
  setSavedFlag,
  setIsDirty,
  setSelectMarkfinal,
  setSelectAll,
  setDownloadEnabled,
  downloadEnabled,
  setCount,
  removeWarning

) => {
  setIsDirty(false);
  setSuccessFlag(false);
  setFailedFlag(false);
  setSavedFlag(false);
  const allIds = [];

  // const filenetIds = allIds.join(',');

  let fileListsTemp = [];
  if (sessionStorage.getItem('fileNetIdLists')) {
    fileListsTemp = JSON.parse(sessionStorage.getItem('fileNetIdLists'));
  }
  const filenetIds = fileListsTemp.join(',');
  setLoading(true);
  // Below is URL shared by backend team
  // /document-management/file-upload/v1/files/edmUpload?filenetIdList=27bc82da-54b5-4105-a774-41432935d7d5
  const url = `${endpoints.api}/document-management/file-upload/v1/files/permanentLocation?fileIdList=${filenetIds}`;
  // const url = `${endpoints.api}/document-management/file-upload/v1/files/edmUpload?filenetIdList=${filenetIds}`;

  callApi
    .postApi(url, '')
    .then((response) => {
      if (response.status === 200) {
        getDocumentListing(setReportingDetailsData);
        setSuccessFlag(true);
        setSavedFlag(true);
        setIsDirty(false);
        removeWarning()
        setMessageContent('Saved successfully.');
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          setSuccessFlag(false);
          setSelectMarkfinal(false);
          setSelectAll(false);
          setDownloadEnabled(false);
          setCount(0);
        }, 5000);
      }
      setLoading(false);
      sessionStorage.removeItem('fileNetIdLists');
      sessionStorage.removeItem('fileDownloadIdLists');
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });

  reportingDetailsData.forEach((item) => {
    if (item.MarkedasFinalValue) {
      allIds.push(item.id);
    }
  });

};

export const deleteFile = (
  setErrorModal,
  setLoading,
  deleteId,
  setReportingDetailsData,
  setActionsDrop,
  setDeleteId
) => {
  setErrorModal(false);
  setLoading(true);
  const deleteURL = `${endpoints.api}/document-management/file-upload/v1/files/${deleteId}`;
  callApi
    .deleteApi(deleteURL)
    .then((result) => {
      getDocumentListing(setReportingDetailsData);
      setLoading(false);
      setActionsDrop(0);
      setDeleteId('');
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
      setActionsDrop(0);
    });
};

export const handleWordCount = (
  event,
  setCommentTxt,
  setCommentCharsLeft,
  setCharCountWarn
) => {
  setCommentCharsLeft(event.target.value.length);
  if (event.target.value.length === 301) {
    setCharCountWarn(
      <span aria-live="assertive" role="alert" className="max300-char-error">
        Your message can&apos;t be more than 300 characters.
      </span>
    );
  } else {
    setCommentTxt(event.target.value);
    setCharCountWarn('');
  }
};

export const getAllMessages = (setLoading, setAllMessages) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${endpoints.apigee
    }/document-management/cdc/messaging/v1/messages/${getQS(
      'id'
    )}?userTimeZone=${tmZone}`;
  callApi
    .getApiDataRequirement(getMsgApiURL)
    .then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item.commentsTimeStamp).format('MM/DD/YYYY');
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessages(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};


export const submitMessageFn = (
  commentTxt,
  setCommentTxt,
  setCommentCharsLeft,
  setLoading,
  setAllMessages,
  setCharCountWarn
) => {

  if (commentTxt.length > 0) {
    setLoading(true);
    setCharCountWarn('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxt,
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxt('');
        setCommentCharsLeft(0);
        getAllMessages(setLoading, setAllMessages);
      })
      .catch(() => {
        // console.log(error)
        history.push('/Error');
      });
  }
};




export const getAllMessagesPSA = (setLoading, setAllMessagesPSA) => {
  setLoading(true);
  const tmZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const getMsgApiURL = `${endpoints.apigee
    }/document-management/cdc/messaging/v1/messages/${getQS(
      'id'
    )}?userTimeZone=${tmZone}&userType=PSA`;
  callApi
    .getApiDataRequirement(getMsgApiURL)
    .then((results) => {
      const msgResults = [];
      results.data.forEach((item) => {
        const temp1 = item.commentsTimeStamp.replace('T', ' ');
        const temp2 = temp1.split('.');
        const temp = item;
        const today = moment(new Date(), 'YYYYMMDD').format('MM/DD/YYYY');
        const commentdate = moment(item.commentsTimeStamp).format('MM/DD/YYYY');
        temp.commentsTimeStamp = moment(temp2[0], 'yyyy-mm-dd HH:mm:ss').format(
          'hh:mm A'
        );
        if (commentdate === today) {
          temp.commentsDate = '';
        } else {
          temp.commentsDate = commentdate;
        }
        msgResults.push(temp);
      });
      setAllMessagesPSA(msgResults);
      setLoading(false);
    })
    .catch(() => {
      // console.log(error)
      setLoading(false);
    });
};
export const submitMessageFnPSA = (
  commentTxtPSA,
  setCommentTxtPSA,
  setCommentCharsLeftPSA,
  setLoading,
  setAllMessagesPSA,
  setCharCountWarnPSA
) => {

  if (commentTxtPSA.length > 0) {
    setLoading(true);
    setCharCountWarnPSA('');
    const data = {
      docId: getQS('id'),
      userId: sessionStorage.getItem('loggedUser'),
      comment: commentTxtPSA,
      userType: "PSA",
    };
    const url = `${endpoints.apigee}/document-management/cdc/messaging/v1/documents/message`;
    return callApi
      .postApi(url, data)
      .then((res) => {
        setCommentTxtPSA('');
        setCommentCharsLeftPSA(0);
        getAllMessagesPSA(setLoading, setAllMessagesPSA);
      })
      .catch(() => {
        history.push('/Error');
      });
  }
};

export const getMessageCount = (user, setMessageCount) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count`;
  callApi
    .getApiDataRequirement(listUrl)
    .then((response) => {
      setMessageCount(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const getMessageCountPSA = (user, setMessageCountPSA) => {
  const listingId = getQS('id');
  const listUrl = `${endpoints.apigee}/document-management/cdc/alerts/v1/users/userId/documents/${listingId}/notifications-count?userType=PSA`;
  callApi
    .getApiDataRequirement(listUrl)
    .then((response) => {
      setMessageCountPSA(response.data.count);
    })
    .catch(() => {
      // console.log(error)
    });
};

export const activateTabActions = (
  e,
  setactiveTabIndex,
  setMessageCount,
  setMessageCountPSA
) => {
  setactiveTabIndex(e.index);
  if (e.index === 1 || e === '1') {
    setInterval(() => {
      setMessageCount(0);
    }, 3000);
  }

  if (e.index === 2 || e === '2') {
    setInterval(() => {
      setMessageCountPSA(0);
    }, 3000);
  }
};

export const uploadMultipleFiles = (
  files,
  setReportingDetailsData,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setSuccessFlag,
  setUploadedFlag,
  setSelectAll,
  setCount,
  setDownloadEnabled,
  showWarning
) => {
  const acceptedFileExtentions = [
    'pdf',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'xml',
    'jpeg',
    'jpg',
    'xlsm',
  ];
  let uploaded = true;
  let acceptedFiles = 0;
  let total = 0;
  let extError = 0;
  let sizeError = 0;
  const formData = new FormData();
  const extErrorFiles = [];
  const sizeErrorFiles = [];
  formData.append('reqId', getQS('id'));
  formData.append('areaToStore ', 'Temporary');
  formData.append('user ', sessionStorage.getItem('loggedUser'));
  files.forEach((element) => {
    total += 1;
    const ext = element.name.split('.').pop();
    if (acceptedFileExtentions.indexOf(ext.toLowerCase()) < 0) {
      extErrorFiles.push(element.name);
      extError += 1;
    } else if (element.size > 104857600) {
      sizeErrorFiles.push(element.name);
      sizeError += 1;
    } else {
      acceptedFiles += 1;
      formData.append('files', element);
    }
  });
  if (sizeError > 0 && extError > 0) {
    const contentTxt = (
      <div>
        <div>
          We were unable to upload the following documents. Make sure each file
          is 100 MB or less and try again.
        </div>
        {sizeErrorFiles.map((item) => (
          <div>
            • &nbsp;
            {item}
          </div>
        ))}
        <div>
          We were unable to upload the following documents. Make sure your files
          are in a supported format (.pdf, .xls, .xlsx, .doc, .docx, .jpeg,
          .jpg, .xml or .xlsm) and try again.
        </div>
        {extErrorFiles.map((item) => (
          <div>
            • &nbsp;
            {item}
          </div>
        ))}
      </div>
    );
    setFailedFlag(true);
    uploaded = false;
    setUploadedFlag(false);
    setMessageContent(contentTxt);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setFailedFlag(false);
    }, 5000);
  } else if (extError > 0) {
    if (extError === total) {
      if (total === 1) {
        setFailedFlag(true);
        setUploadedFlag(false);
        setMessageContent(
          `We were unable to upload the document. Make sure your file is in a supported format (.pdf, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .xml or .xlsm) and try again.`
        );
      } else {
        setFailedFlag(true);
        setUploadedFlag(false);
        setMessageContent(
          `We were unable to upload ${total} of ${total} documents. Make sure your files are in a supported format (.pdf, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .xml or .xlsm) and try again.`
        );
      }
      setTimeout(() => {
        setFailedFlag(false);
      }, 5000);
    } else {
      const contentTxt = (
        <div>
          <span>
            We were unable to upload the following documents. Make sure your
            files are in a supported format (.pdf, .xls, .xlsx, .doc, .docx,
            .jpeg, .jpg, .xlsm or .xml) and try again.
          </span>
          {extErrorFiles.map((item) => (
            <div>
              • &nbsp;
              {item}
            </div>
          ))}
        </div>
      );
      setFailedFlag(true);
      setUploadedFlag(false);
      uploaded = false;
      setMessageContent(contentTxt);
      setTimeout(() => {
        setFailedFlag(false);
      }, 5000);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } else if (sizeError > 0) {
    if (total === 1) {
      setFailedFlag(true);
      setUploadedFlag(false);
      uploaded = false;
      setMessageContent(
        'We were unable to upload your document. Make sure your file is 100 MB or less and try again.'
      );
      setTimeout(() => {
        setFailedFlag(false);
      }, 5000);
    } else {
      const contentTxt = (
        <div>
          <span>
            We were unable to upload the following documents. Make sure each
            file is 100 MB or less and try again.
          </span>
          {sizeErrorFiles.map((item) => (
            <div>
              • &nbsp;
              {item}
            </div>
          ))}
        </div>
      );
      setFailedFlag(true);
      setUploadedFlag(false);
      uploaded = false;
      setMessageContent(contentTxt);
      setTimeout(() => {
        setFailedFlag(false);
      }, 5000);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  const uid = sessionStorage.getItem('loggedUser');
  const did = getQS('id');

  if (acceptedFiles === 1) {
    setLoading(true);
    const getApiURL = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/${did}/files`;
    callApi
      .postApiUpload(getApiURL, formData)
      .then((response) => {
        if (response.status === 200) {
          getDocumentListing(setReportingDetailsData);
          setSelectAll(false);
          setCount(0);
          setDownloadEnabled(false);
          if (total === acceptedFiles) {
            setSuccessFlag(true);
            uploaded = true;
            setUploadedFlag(true);
            showWarning();
            setMessageContent('You’ve successfully uploaded your document.');
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            setTimeout(() => {
              setSuccessFlag(false);
            }, 5000);
          }
        } else {
          // console.log('upload failed')
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.statusCode == 400) {
          setMessageContent(e?.response?.data?.errorMessage);

          setFailedFlag(true);
        }
        // console.log(error)
        setLoading(false);
      });
  }
  if (acceptedFiles > 1) {
    setLoading(true);
    const getApiURL = `${endpoints.api}/document-management/file-upload/v1/users/userId/documents/${did}/files`;
    callApi
      .postApiUpload(getApiURL, formData)
      .then((response) => {
        if (response.status === 200) {
          getDocumentListing(setReportingDetailsData);
          if (total === acceptedFiles) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            setSuccessFlag(true);
            setUploadedFlag(true);
            setMessageContent(
              `You’ve successfully uploaded ${total} of ${total} documents.`
            );
            setTimeout(() => {
              setSuccessFlag(false);
            }, 5000);
          }
        } else {
          // console.log('upload failed')
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.statusCode != 200) {
          setMessageContent(e?.response?.data?.errorMessage);

          setFailedFlag(true);
        }
        // console.log(error)
        setLoading(false);
      });
  }
  if (uploaded) {
    return true;
  } else {
    return null;
  }
};

export const onDrop = (
  acceptedFiles,
  setReportingDetailsData,
  setSuccessFlag,
  setFailedFlag,
  setMessageContent,
  setLoading,
  setUploadedFlag,
  setSelectAll,
  setCount,
  setDownloadEnabled,
  showWarning
) => {
  // Won't work in IE, Edge
  // const renamedAcceptedFiles = acceptedFiles.map(
  //   file =>
  //     new File([file], `${file.name.replace(/ +/g, '_')}`, {
  //       type: file.type
  //     })
  // )
  setSuccessFlag(false);
  setFailedFlag(false);
  uploadMultipleFiles(
    acceptedFiles,
    setReportingDetailsData,
    setFailedFlag,
    setMessageContent,
    setLoading,
    setSuccessFlag,
    setUploadedFlag,
    setSelectAll,
    setCount,
    setDownloadEnabled,
    showWarning
  );
};

export const handleDrop = (files, event) => {
  // console.log(files, event)
};

export const handleBrowse = (event) => {
  // console.log(event.target.files)
};

export const closeMessage = (setFailedFlag, setSuccessFlag) => {
  setFailedFlag(false);
  setSuccessFlag(false);
};

export const closeModal = (setDeleteId, setErrorModal) => {
  setDeleteId('');
  setErrorModal(false);
  // setLegalHold(false)
};

export const closeRenameModalPopup = (
  setCloseRenameModal,
  setFileRename,
  setFileRenameError
) => {
  setCloseRenameModal(false);
  setFileRename('');
  setFileRenameError(false);
};

export const saveRenamedFile = (
  setLoading,
  setCloseRenameModal,
  fileRename,
  setFileRename,
  currentFilenetId,
  setReportingDetailsData,
  setFileRenameError,
  showWarning
) => {
  setLoading(true);
  let editedName = '';
  if (fileRename === '') {
    editedName = 'EditPopUpEmpty';
  } else {
    editedName = fileRename;
  }

  // get storage value
  let fileLists = [];
  if (sessionStorage.getItem('fileNetIdLists')) {
    fileLists = JSON.parse(sessionStorage.getItem('fileNetIdLists'));

    // hit API with list of ids
    const listUrl = `${endpoints.api
      }/document-management/file-upload/v1/files/details?fileIdList=${fileLists.toString()}&markasfinalFlag=true`;
    callApi
      .patchApi(listUrl, '')
      .then((response) => {
        getDocumentListing(setReportingDetailsData);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const url = `${endpoints.api}/document-management/file-upload/v1/files/fileRename?editedFilename=${editedName}&fileId=${currentFilenetId}`;
  callApi
    .postApi(url, '')
    .then((response) => {
      if (response.status === 200) {
        setCloseRenameModal(false);
        setLoading(false);
        getDocumentListing(setReportingDetailsData);
        setFileRename('');
        setFileRenameError(false);
        showWarning()
      }
    })
    .catch(() => {
      setLoading(false);
      // console.log(error)
    });
};

export const massDownloadDocument = (
  setLoading,
  getDocumentListing,
  setReportingDetailsData,
  count,
  setCount,
  setDownloadEnabled,
  downloadEnabled,
  setSelectAll,
  setMessageContent,
  setFailedFlag
) => {
  setLoading(true);

  let fileId = [];
  if (sessionStorage.getItem('fileDownloadIdLists')) {
    fileId = JSON.parse(sessionStorage.getItem('fileDownloadIdLists'));
  }

  const apiURL = `${endpoints.api}/document-management/file-download/v1/files?fileIdList=${fileId}`;
  callApi
    .getApiData(apiURL)
    .then((result) => {
      let count = 0
      const downloadInterval = setInterval(() => {
        try {
          saveAsFile(
            JSON.parse(result.data[count].binaryContent).binary,
            JSON.parse(result.data[count].binaryContent).fileName
          )
          count++
        } catch (error) {
          setLoading(false)
          clearInterval(downloadInterval)
        }
      }, 200);
      getDocumentListing(setReportingDetailsData);
      setDownloadEnabled(!downloadEnabled);
      sessionStorage.removeItem('fileDownloadIdLists');
      setSelectAll(false);
    })
    .catch((error) => {
      console.log('error', error);
      if (error?.response?.data?.statusCode !== 200) {
        setMessageContent(error?.response?.data?.errorMessage);

        setFailedFlag(true);
      }
      setLoading(false);
    });
};

export const handleRenamedTxt = (
  event,
  setFileRename,
  setRenameLength,
  setFileRenameError,
  setMessageContent,
  setFailFlagRename
) => {
  const searchData = /[`%^*+<>:?#\/\]\[\\]/;
  let replaced = event.target.value.search(searchData);
  const sanitizedValue = event.target.value.replace(/[`%^*+<>:?#\/\]\[\\]/gi,'');
  if(replaced === -1){
      setFailFlagRename(false);
      setFileRename(sanitizedValue);
      setRenameLength(sanitizedValue.length); 
  }else{
    setFailFlagRename(true);
    setMessageContent("Following characters are invalid : [/\\`%^*+<>:?#]");
  }

  if (sanitizedValue.length >= 15) {
    setFileRenameError(true);
  } else {
    setFileRenameError(false);
  }
};

export const closeAllmodal = (
  setErrorModal,
  setCloseRenameModal,
  setDocumentsModalOpen
) => {
  setErrorModal(false);
  setCloseRenameModal(false);
  setDocumentsModalOpen(false);
};

export const downloadDocTemplate = (setLoading, category, doctype) => {
  // docFilenetId
  let docSubType = '';

  setLoading(true);

  if (doctype === null || doctype === '') {
    docSubType = 'NA';
  } else {
    docSubType = doctype;
  }

  const apiURL = `${endpoints.api}/document-management/file-download/v1/documents/DocumentTemplates/files-filter?category=${category}&subType=${docSubType}`;
  callApi
    .getApiDataRequirement(apiURL)
    .then((result) => {
      const fileName = result.data.fileName.replace(
        'Content-Disposition: attachment; filename=',
        ''
      );
      saveAsFile(result.data.binary, fileName);
      setLoading(false);
    })
    .catch(() => {
      // console.log('error', error)
      setLoading(false);
    });
};

const RequirementDetails = () => {
  const [count, setCount] = useState(0);
  const [loadinger, setLoading] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [commentTxt, setCommentTxt] = useState('');
  const [commentTxtPSA, setCommentTxtPSA] = useState('');
  const [commentCharsLeft, setCommentCharsLeft] = useState(0);
  // const [commentError, setCommentError] = useState('')
  const [charCountWarn, setCharCountWarn] = useState('');

  const [commentCharsLeftPSA, setCommentCharsLeftPSA] = useState(0);
  const [charCountWarnPSA, setCharCountWarnPSA] = useState('');
  const [openedAccordion, setOpenedAccordion] = useState(0);
  const [actionsDrop, setActionsDrop] = useState('');
  const [requirementResults, setRequirementResults] = useState({
    metadata: {},
  });
  const [reportingDetailsData, setReportingDetailsData] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [allMessagesPSA, setAllMessagesPSA] = useState([]);
  const [messageCountPSA, setMessageCountPSA] = useState(0);
  const [deleteId, setDeleteId] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalTxt, setErrorModalTxt] = useState('');
  const [errorModalTitle, setErrorModalTitle] = useState('');
  const [successFlagRename, setSuccessFlagRename] = useState(false);
  const [failFlagRename, setFailFlagRename] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [uploadedFlag, setUploadedFlag] = useState(false);
  const [savedFlag, setSavedFlag] = useState(false);
  const [failedFlag, setFailedFlag] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [fileRename, setFileRename] = useState('');
  const [fileRenameError, setFileRenameError] = useState(false);
  const [closeRenameModal, setCloseRenameModal] = useState(false);
  const [renameLength, setRenameLength] = useState(0);
  const [renamedTextReceived, setRenamedTextReceived] = useState('');
  const [currentFilenetId, setCurrentFilenetId] = useState('');
  const [hideExcel, setHideExcel] = useState(false);
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [excelData, setExcelData] = useState([]);
  const [downloadEnabled, setDownloadEnabled] = useState(false);
  const [fileType, setFileType] = useState('');
  const [legalHold, setLegalHold] = useState(false);
  const [docTemplateFlag, setDocTemplateFlag] = useState(false);
  // const [docFilenetId, setDocFilenetId] = useState('')
  const [documentReport, setDocumentReport] = useState(false);
  const [viewPortfolioModalOpen, setViewPortfolioModalOpen] = useState(false);
  const [submitforReviewModalOpen, setSubmitForReviewModalOpen] = useState(false);
  const { setCurrentMenu } = useContext(DocContext);
  const [locationKeys, setLocationKeys] = useState([]);
  const [fileDownload, setFileDownload] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectMarkfinal, setSelectMarkfinal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [Prompt,showWarning,removeWarning] = useWarningMessage();
  const history = useHistory();
  const tempUserRole = useUserRole();

  useEffect(() => {
    if (sessionStorage.getItem('fileNetIdLists')) {
      sessionStorage.removeItem('fileNetIdLists');
    }

    if (sessionStorage.getItem('fileDownloadIdLists')) {
      sessionStorage.removeItem('fileDownloadIdLists');
    }

    if (!sessionStorage.getItem('tok')) {
      if (getQS('id')) {
        localStorage.setItem('redurl', getQS('id'));
        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        if (getQS('from') || currentUrl.includes("dns.com")) {
          window.location.href = '/Employee';
        } else {
          window.location.href = '/';
        }
      }
    } else {
      setCurrentMenu('portfolio');
      localStorage.removeItem('redurl');

      if (sessionStorage.getItem('userrole') === 'employee') {
        setEmployee(true);
      }
      if (
        sessionStorage.getItem('servicingrole') === 'vendorsiteadmin' ||
        sessionStorage.getItem('servicingrole') === 'vendorcontributor' ||
        sessionStorage.getItem('servicingrole') === 'unlistedvendor'
      ) {
        setVendor(true);
      }
      getDocDetails(setRequirementResults, setLoading, setDocTemplateFlag); // setDocFilenetId
      getDocumentListing(setReportingDetailsData);
      getMessageCount(sessionStorage.getItem('assignedUser'), setMessageCount);
      getMessageCountPSA(sessionStorage.getItem('assignedUser'), setMessageCountPSA);
      const qsParam = getQS('index') ? 
      (sessionStorage.getItem('userrole') === 'employee' && (!sessionStorage.getItem('remote_user'))
      ? getQS('index') : 1) : 0;
      if (qsParam === '1' || qsParam === '2') {
        activateTabActions(
          qsParam,
          setactiveTabIndex,
          setMessageCount,
          setMessageCountPSA
        );
      }
      setactiveTabIndex(Number(qsParam));
      getAllMessages(setLoading, setAllMessages);
      getAllMessagesPSA(setLoading, setAllMessagesPSA);
    }
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        closeAllmodal(
          setErrorModal,
          setCloseRenameModal,
          setDocumentsModalOpen
        );
      }
    });
  }, []);

  // useLayoutEffect(() => {
  //   console.log("mounted")
  //   return () => {
  //     console.log("unmounted")
  //     showViewPortfolioModal()
  //     // && history.location.pathname === "any specific path")if (history.action === "POP") {
  //       // history.replace(history.location.pathname, /* the new state */);
  //     }
  //   }
  // , [history])

  // useEffect(()=>{
  //   return history.listen(location => {
  //     if(history.action === 'POP'){

  //       if(locationKeys[1]===location.key){
  //         setLocationKeys(([_, ...keys]) => keys)
  //         console.log("forward")
  //       } else {
  //         setLocationKeys((keys) => [location.key, ...keys])
  //         console.log("backward")
  //       }
  //     }
  //   })
  // },[locationKeys,])

  // useEffect(()=>{
  //   console.log("hello")
  //   // const handleRouteChange = (url) => {
  //   //   console.log(`App is changing to ${url}`)
  //   //   if(url =="/ReportingDashboard"){
  //   //     showViewPortfolioModal()
  //   //   }
  //   // }
  //   // Router.events.on('beforeHistoryChange',handleRouteChange)
  //   Router.beforePopState(({url ,as , options})=>{
  //     if(as =="/ReportingDashboard"){
  //       showViewPortfolioModal()
  //       return false
  //     }
  //     return true
  //   })
  // },[])

  //   useEffect(() => {

  //     return () => {
  //       console.log("a")
  //         if (history.action === 'POP') {
  //           console.log("b")
  //             history.go(1);
  //         }
  //     };
  // }, [history]);

  //   useEffect(()=>{
  //     console.log("mounted")
  //     return () => {
  //       console.log("unmount")
  //       // Anything in here is fired on component unmount.
  //       showViewPortfolioModal()

  //   }
  // }, [])

  // useEffect(()=>{
  //   Router.events.on('POP',()=>{alert("pressed back")})
  // })
  /* ============ Report download modal specific =============  */
  const showDocumentsModal = () => {
    setFileType('');
    setModalTitle('Download document history report.')
    setDocumentsModalOpen(true);
    setDocumentReport(true);
    addAriaLabel();
  };

  const closeDocumentsModal = () => {
    setFileType('');
    setHideExcel(false);
    setDocumentReport(false);
    setDocumentsModalOpen(false);
  };

  const showMessagesModal = () => {
    setFileType('');
    setModalTitle('Download message history report.')
    setDocumentsModalOpen(true);
    setDocumentReport(false);
    addAriaLabel();
  };

  const showViewPortfolioModal = (removeWarning) => {
    removeWarning();
    if (employee && !sessionStorage.getItem('remote_user')) {
      isDirty || (uploadedFlag && !savedFlag)
        ? setViewPortfolioModalOpen(true)
        : (window.location.href = '/ReportingDashboard');
    } else {
      uploadedFlag
        ? setViewPortfolioModalOpen(true)
        : (window.location.href = '/ReportingDashboard');
    }
  };

  const closeViewPortfolioModal = (showWarning) => {
    showWarning()
    setViewPortfolioModalOpen(false);
  };

  const showSubmitForReviewModal = (
    setLoading,
    setSuccessFlag,
    setFailedFlag,
    setMessageContent,
    setRequirementResults,
    setDocTemplateFlag,
    setSavedFlag,
    setUploadedFlag,
    setIsDirty,
    removeWarning,
    requirementResults) => {

    if (reportingDetailsData.filter(item => 
      item.edmStatus == 'SentToEdmTemp'
    ).length > 0) {
      setSubmitForReviewModalOpen(true)
    } else {
      submitforReview(
        setLoading,
        setSuccessFlag,
        setFailedFlag,
        setMessageContent,
        setRequirementResults,
        setDocTemplateFlag,
        setSavedFlag,
        setUploadedFlag,
        setIsDirty,
        removeWarning
      )
    }

  }

  const closeSubmitForReviewModal = () => {
    setSubmitForReviewModalOpen(false);
  };

  const fileTypes = [{ label: 'Microsoft Excel (xlsx)', value: 'xlsx' }];

  const setDownloadFileType = (e, repType) => {
    let tempdata;
    if (repType === 'messages') {
      tempdata = [];
      allMessages.forEach((item) => {
        tempdata.push({
          AS_OF_DATE: requirementResults.asOfDate,
          DEAL_NAME: requirementResults.metadata.dealName,
          DEAL_NUMBER: requirementResults.metadata.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults.metadata.reportingEntity,
          PARENT_COMPANY: requirementResults.metadata.parentCompanyName,
          NAME: item.userName,
          COMMENT: `'${item.comment}`,
          COMMENTED_AT: item.commentsTimeStamp,
        });
      });
    } else if (repType === 'documents') {
      tempdata = [];
      reportingDetailsData.forEach((item) => {
        tempUserRole === 'employee' ? tempdata.push({
          AS_OF_DATE: requirementResults.asOfDate,
          DEAL_NAME: requirementResults.metadata.dealName,
          DEAL_NUMBER: requirementResults.metadata.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults.metadata.reportingEntity,
          PARENT_COMPANY: requirementResults.metadata.parentCompanyName,
          FILE_NAME: item.title,
          DOCUMENT_TITLE: item.printfilename,
          UPLOADED_DATE: moment(item.uploadedDate).format('MMM DD, YYYY'),
          UPLOADED_BY: item.uploadedByName,
          FINAL:
            item.MarkedasFinalValue === true
              ? 'Marked final'
              : 'Not marked final',
        }) : tempdata.push({
          AS_OF_DATE: requirementResults.asOfDate,
          DEAL_NAME: requirementResults.metadata.dealName,
          DEAL_NUMBER: requirementResults.metadata.dealId,
          STATUS: requirementResults.status,
          DUE_DATE: moment(requirementResults.dueDate).format('MMM DD, YYYY'),
          LEGAL_ENTITY: requirementResults.metadata.reportingEntity,
          PARENT_COMPANY: requirementResults.metadata.parentCompanyName,
          FILE_NAME: item.title ? item.title : item.printfilename,
          UPLOADED_DATE: moment(item.uploadedDate).format('MMM DD, YYYY'),
          UPLOADED_BY: item.uploadedByName,
          FINAL:
            item.MarkedasFinalValue === true
              ? 'Marked final'
              : 'Not marked final',
        });
      });
    }
    setFileType(e.target.value);
    setExcelData(tempdata);
    if (e.target.value === 'pdf') {
      setHideExcel(false);
    } else {
      setHideExcel(true);
    }
  };

  /* ============ Report download modal specific ends ========  */

  return (
    <>
      <USBGrid addClasses="content-frame">
        {Prompt}
        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('remote_user') ? (
            <div className="warning-panel-remote-req-details">
              You are impersonating the user:{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).firstName}{' '}
              {JSON.parse(sessionStorage.getItem('remote_user')).lastName}. To
              exit, click the 'Exit User' link above.
            </div>
          ) : (
            ''
          )}
        </USBColumn>


        <USBColumn largeSpan={16}>
          {sessionStorage.getItem('filesToCopy') ? (
            <div className="warning-panel-remote">
  
  You cannot paste here, please select Closing Binder requirement from &nbsp;
  <a href={`/ReportingDashboard`} title="Portfolio">
  Portfolio
            </a>,

              To cancel copying, click &nbsp;

              <USBButton
                    size="small"
                    variant='secondary'
                    handleClick={
                      () => { sessionStorage.removeItem('filesToCopy'); window.location.reload(); }
                    }
                    ariaLabel='Cancel Copy'
                  >
                    Cancel
                  </USBButton>
            </div>
          ) : (
            ''
          )}
        </USBColumn>

        <USBColumn largeSpan={8}>
          <USBBreadcrumb
            id="details"
            text="Home"
            breadcrumbs={[
              {
                id: 1,
                text: 'My portfolio',
                href: '/ReportingDashboard',
              },
              { id: 2, text: 'Reporting requirement details', href: '' },
            ]}
          />
        </USBColumn>
        <USBColumn largeSpan={8} />
      </USBGrid>
      <USBGrid addClasses="content-frame">
        <USBColumn largeSpan={16}>
          {loadinger ? <Loader /> : null}
          <USBModal className="delete-modal" isOpen={errorModal}>
            <USBModalCloseIcon
              handleModalClose={() => closeModal(setDeleteId, setErrorModal)}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={errorModalTitle} />
            <USBModalBody>
              <div className="errormodalbody">
                <div className="deletetextframe">{errorModalTxt}</div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              {legalHold ? (
                <div className="deletebtnframe">
                  <USBButton
                    variant="primary"
                    handleClick={() => closeModal(setDeleteId, setErrorModal)}
                  >
                    OK
                  </USBButton>
                </div>
              ) : (
                <div className="deletebtnmodalframe">
                  <span className="cancel-btn">
                    <USBButton
                      id="cancelButton"
                      handleClick={() => closeModal(setDeleteId, setErrorModal)}
                    >
                      Cancel
                    </USBButton>
                  </span>
                  <USBButton
                    variant="primary"
                    handleClick={() =>
                      deleteFile(
                        setErrorModal,
                        setLoading,
                        deleteId,
                        setReportingDetailsData,
                        setActionsDrop,
                        setDeleteId
                      )
                    }
                  >
                    Delete
                  </USBButton>
                </div>
              )}
            </USBModalFooter>
          </USBModal>
          <USBModal className="rename-modal" isOpen={closeRenameModal}>
            <USBModalCloseIcon
              handleModalClose={() =>
                closeRenameModalPopup(
                  setCloseRenameModal,
                  setFileRename,
                  setFileRenameError
                )
              }
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle="Rename your document." />
           
            <USBModalBody>
              <div className="renamemodal-body">
                <p>
                  We’ve automatically renamed your document. If you’d like to
                  add more detail to the file name, enter it in the &nbsp;
                  <strong>Edit file name</strong>
                  &nbsp;field.
                </p>
                <p>
                  When you’re done, select&nbsp;
                  <strong>Save.</strong>
                </p>
                <p>
                  <span className="caption">Document name</span>
                  <br />
                  <span className="black">{renamedTextReceived}</span>
                </p>
                {fileRename.length === 0 ? (
                  <div className="emptycaption height20" />
                ) : (
                  <div className="height20">
                    {fileRename.length < 15 ? (
                      <span className="caption blue">Edit file name</span>
                    ) : (
                      <span className="caption red">Edit file name</span>
                    )}
                  </div>
                )}
                <div className="renametextframe">
                  <div className="renametextframein">
                    <input
                      aria-label="rename"
                      maxLength="15"
                      type="text"
                      name="renametext"
                      placeholder="Edit file name"
                      onChange={(e) =>
                        handleRenamedTxt(
                          e,
                          setFileRename,
                          setRenameLength,
                          setFileRenameError,
                          setMessageContent,
                          setFailFlagRename,
                        )
                      }
                      value={fileRename}
                    />
                  </div>
                  <div className="renametextframe-left">
                    {fileRenameError ? (
                      <span
                        aria-live="polite"
                        role="alert"
                        className="caption red"
                      >
                        Character limit reached
                      </span>
                    ) : (
                      <span className="caption">Optional</span>
                    )}
                  </div>
                  <div className="renametextframe-right">
                    {fileRenameError ? (
                      <span className="caption red">
                        {renameLength}
                        &nbsp;/ 15
                      </span>
                    ) : (
                      <span className="caption">
                        {renameLength}
                        &nbsp;/ 15
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {successFlagRename || failFlagRename  ? (
              <div
                className="message-content"
                data-testid="messageContent"
                role="alert"
              >
                <Messages
                  success={successFlagRename}
                  closeMessage={closeMessage}
                  singleUploadFail={failFlagRename}
                  messageContent={messageContent}
                  setFailedFlag={setFailFlagRename}
                  setSuccessFlag={setSuccessFlagRename}
                  severity={'high'}
                />
              </div>
            ) : (
              ''
            )}
            </USBModalBody>
            <USBModalFooter>
              <div className="deletebtnmodalframe">
                <span className="cancel-btn">
                  <USBButton
                    id="cancelButton"
                    handleClick={() =>
                      closeRenameModalPopup(
                        setCloseRenameModal,
                        setFileRename,
                        setFileRenameError
                      )
                    }
                  >
                    Cancel
                  </USBButton>
                </span>
                <USBButton
                  variant="primary"
                  handleClick={() =>
                    saveRenamedFile(
                      setLoading,
                      setCloseRenameModal,
                      fileRename,
                      setFileRename,
                      currentFilenetId,
                      setReportingDetailsData,
                      setFileRenameError
                    )
                  }
                >
                  Save
                </USBButton>
              </div>
            </USBModalFooter>
          </USBModal>
          <USBModal className="download-modal" isOpen={documentsModalOpen}>
            <USBModalCloseIcon
              handleModalClose={() => closeDocumentsModal()}
              className="errormodalclosebtn"
            />
            <USBModalHeader modalTitle={modalTitle} />
            <USBModalBody>
              <div className="modal-description">
                To download the report, first choose your preferred format from
                the&nbsp;
                <strong>File type</strong>
                &nbsp;menu
              </div>
              <div>
                <div className="download-file-type-dropdown">
                  <Dropdown
                    value={fileType}
                    options={fileTypes}
                    onChange={
                      documentReport
                        ? (e) => setDownloadFileType(e, 'documents')
                        : (e) => setDownloadFileType(e, 'messages')
                    }
                    placeholder="File Type"
                    className="file-type"
                    ariaLabel="file type"
                  />
                </div>
              </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="reportDownloadModal">
                <span className="report-cancel-btn">
                  <USBButton addClasses={'button-hover-white'} handleClick={() => closeDocumentsModal()}>
                    Cancel
                  </USBButton>
                </span>
                {hideExcel && (
                  <USBButton
                    variant="primary"
                    handleClick={
                      documentReport
                        ? () =>
                          ExportToExcel(
                            Object.values(excelData),
                            'Document_History',
                            setDocumentsModalOpen,
                            setHideExcel
                          )
                        : () =>
                          ExportToExcel(
                            Object.values(excelData),
                            'Messaging_History',
                            setDocumentsModalOpen,
                            setHideExcel
                          )
                    }
                  >
                    Download
                  </USBButton>
                )}
              </div>
            </USBModalFooter>
          </USBModal>
          <USBModal className="download-modal" isOpen={viewPortfolioModalOpen}>
            <USBModalCloseIcon
              handleModalClose={() => closeViewPortfolioModal(showWarning)}
            />
            <USBModalHeader modalTitle="Reporting requirement details"></USBModalHeader>
            <USBModalBody>
              {employee && !sessionStorage.getItem('remote_user') ? (
                <div className="modal-description">
                  You have not submitted and saved your changes. Are you sure
                  you want to leave this page?
                </div>
              ) : (
                <div className="modal-description">
                  You have not submitted for review the uploaded document. Are
                  you sure you want to leave this page?
                </div>
              )}
            </USBModalBody>
            <USBModalFooter>
              <div className="button-details page-sub-link-nofile">
                <div className="">
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => history.goBack()}
                    >
                      Yes
                    </USBButton>
                  </div>
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => closeViewPortfolioModal(showWarning)}
                    >
                      No
                    </USBButton>
                  </div>
                </div>
              </div>
            </USBModalFooter>
          </USBModal>
          <USBModal className="download-modal" isOpen={submitforReviewModalOpen}>
            <USBModalCloseIcon
              handleModalClose={() => closeSubmitForReviewModal()}
            />
            <USBModalHeader modalTitle="Reporting requirement details"></USBModalHeader>
            <USBModalBody>
                <div className="modal-description">
                  Some files are still uploading. Do you wish to continue?
                </div>
            </USBModalBody>
            <USBModalFooter>
              <div className="button-details page-sub-link-nofile">
                <div className="">
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => {
                        setSubmitForReviewModalOpen(false)
                        submitforReview(
                          setLoading,
                          setSuccessFlag,
                          setFailedFlag,
                          setMessageContent,
                          setRequirementResults,
                          setDocTemplateFlag,
                          setSavedFlag,
                          setUploadedFlag,
                          setIsDirty,
                          removeWarning
                        )}
                      }
                    >
                      Yes
                    </USBButton>
                  </div>
                  <div className="single-buttons">
                    <USBButton
                      variant="primary"
                      handleClick={() => closeSubmitForReviewModal()}
                    >
                      No
                    </USBButton>
                  </div>
                </div>
              </div>
            </USBModalFooter>
          </USBModal>

          <h1 className="heading-h1 sentance-case">
            {requirementResults.category}
          </h1>
          <h3 className="heading-h3">{requirementResults.requirementName}</h3>
          <div className="sub-head-small">
            <div className="date-status-details">
              <span className="font-bold light-black">As of date:</span>
              {/* <span className="font-grey">{moment(requirementResults.notificationDate).format('MMM DD, YYYY')}</span> */}
              <span className="font-grey">{requirementResults.asOfDate}</span>
            </div>
            <div className="date-status-details">
              <span className="font-bold light-black">Due date:</span>
              <span className="font-grey">
                {duedateTemplate(requirementResults)}
              </span>
            </div>
            <div className="date-status-details">
              <span className="font-bold light-black">Status:</span>
              <span
                className={
                  requirementResults.status === 'Completed'
                    ? 'green-text'
                    : 'font-grey'
                }
              >
                {requirementResults.status}
              </span>
            </div>
          </div>
          <div className="subheading-report-wrap">
            <div className="sub-heading">
              {(employee && !sessionStorage.getItem('remote_user')) ||
                vendor ? (
                requirementResults.metadata.parentCompanyName === null ||
                  requirementResults.metadata.parentCompanyName === '' ? (
                  ''
                ) : (
                  <div className="date-status-details">
                    <span className="font-bold light-black">
                      Parent company:
                    </span>
                    <span className="font-grey">
                      {requirementResults.metadata.parentCompanyName}
                    </span>
                  </div>
                )
              ) : (
                ''
              )}
              {requirementResults.metadata.dealName === null ||
                requirementResults.metadata.dealName === '' ? (
                ''
              ) : (
                <div className="date-status-details">
                  <span className="font-bold light-black">Deal name:</span>
                  <span className="font-grey">
                    {requirementResults.metadata.dealName}
                  </span>
                </div>
              )}
              {requirementResults.metadata.dealId === null ||
                requirementResults.metadata.dealId === '' ? (
                ''
              ) : (
                <div className="date-status-details">
                  <span className="font-bold light-black">Deal number:</span>
                  <span className="font-grey">
                    {requirementResults.metadata.dealId}
                  </span>
                </div>
              )}
              {requirementResults.metadata.reportingEntity === null ||
                requirementResults.metadata.reportingEntity === '' ? (
                ''
              ) : (
                <div className="date-status-details">
                  <span className="font-bold light-black">Legal entity:</span>
                  <span className="font-grey">
                    {requirementResults.metadata.reportingEntity}
                  </span>
                </div>
              )}
            </div>
            {/* ====== Reporting section ====== */}
            {reportingDetailsData.length >= 1 || allMessages.length >= 1 ? (
              <div className="report-download">
                <div className="report-download-title">DOWNLOAD A REPORT</div>
                {reportingDetailsData !== null &&
                  reportingDetailsData.length >= 1 ? (
                  <button
                    type="button"
                    className="download-link"
                    onClick={() => showDocumentsModal()}
                  >
                    Document history
                  </button>
                ) : (
                  ''
                )}
                {allMessages !== null && allMessages.length >= 1 ? (
                  <button
                    type="button"
                    className="download-link"
                    onClick={() => showMessagesModal()}
                  >
                    Messaging history
                  </button>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {/* ====== Reporting section ends ====== */}
            {successFlag || failedFlag ? (
              <div
                className="message-content"
                data-testid="messageContent"
                role="alert"
              >
                <Messages
                  success={successFlag}
                  closeMessage={closeMessage}
                  singleUploadFail={failedFlag}
                  messageContent={messageContent}
                  setFailedFlag={setFailedFlag}
                  setSuccessFlag={setSuccessFlag}
                  severity={'high'}
                />
              </div>
            ) : (
              ''
            )}
            <div className="page-sub-details">
              To upload or download a document, go to the
              <span className="font-bold"> Documents</span>
              tab. Need to respond to a comment or leave a new comment about
              this requirement? Go to the
              <span className="font-bold"> Messages</span>
              tab.
            </div>

            {employee && !sessionStorage.getItem('remote_user') ? (
              ''
            ) : (
              <div className="page-sub-details page-sub-margin">
                Make sure you’ve provided all the required documents before you
                submit them for review. When you’re ready, select
                <span className="font-bold"> Submit for review.</span>
              </div>
            )}
          </div>

          <div className="tab-view-content">
            <div
              className={
                messageCount > 0 ? 'notification-dot' : 'notification-no-dot'
              }
            >
              <div>&nbsp;</div>
            </div>

            <div
              className={
                messageCountPSA > 0 && tempUserRole && tempUserRole == "employee" ? 'notification-dot-psa' : 'notification-no-dot-psa'
              }
            >
              <div>&nbsp;</div>
            </div>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) =>
                activateTabActions(
                  e,
                  setactiveTabIndex,
                  setMessageCount,
                  setMessageCountPSA
                )
              }
            >
              <TabPanel header="Documents">
                {reportingDetailsData.length === 0 ? (
                  <div>
                    <div className="download-template">
                      <span className="page-sub-empty">
                        There are no documents for this reporting
                        requirement.&nbsp;
                        {docTemplateFlag === true ? (
                          <span>
                            {employee &&
                              !sessionStorage.getItem('remote_user') ? (
                              <span>
                                Want to share a document template with a
                                customer? Select&nbsp;
                                <span className="font-bold">
                                  Download template&nbsp;
                                  {/* &nbsp;{requirementResults.category}&nbsp; */}
                                </span>
                                and upload it here.
                              </span>
                            ) : (
                              <span>
                                Need to create a new document? Select&nbsp;
                                <span className="font-bold">
                                  Download template&nbsp;
                                  {/* &nbsp;{requirementResults.category}&nbsp; */}
                                </span>
                                to get started.
                              </span>
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                      <span className="page-sub-link-nofile">
                        {docTemplateFlag === true ? (
                          // <button
                          //   type="button"
                          //   className="downloadtemplatebtn"
                          //   onClick={() => downloadDocTemplate(setLoading, requirementResults.category, requirementResults.docType)}
                          // >
                          //   <span className="download-blue-small-icon" />
                          //   Download&nbsp;
                          //   {requirementResults.category}
                          //   &nbsp;template
                          // </button>
                          <a
                            href="/DocumentTemplate"
                            rel="help"
                            className="blue-link"
                          >
                            Use a template for this report
                          </a>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="page-sub-frame">
                    <div className="page-sub-details-full">
                      The most recent documents for this reporting requirement are listed.
                    </div>
                    <div className="page-sub-link">
                      {docTemplateFlag === true ? (
                        // <button
                        //   type="button"
                        //   className="downloadtemplatebtn"
                        //   onClick={() => downloadDocTemplate(setLoading, requirementResults.category, requirementResults.docType)}
                        // >
                        //   <span className="download-blue-small-icon" />
                        //   Download&nbsp;
                        //   {requirementResults.category}
                        //   &nbsp;template
                        // </button>
                        <a
                          href="/DocumentTemplate"
                          rel="help"
                          className="blue-link"
                        >
                          Use a template for this report
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="page-sub-details-full page-sub-margin">
                      To upload a new copy of an existing document, go to
                      the&nbsp;
                      <span className="font-bold">Actions</span>
                      menu, then <span className="font-bold">Replace.</span>
                      {docTemplateFlag === true ? (
                        <span>
                          {employee &&
                            !sessionStorage.getItem('remote_user') ? (
                            <span>
                              Want to share a document template with a customer?
                              Select&nbsp;
                              <span className="font-bold">
                                Download template&nbsp;
                                {/* &nbsp;{requirementResults.category}&nbsp; */}
                              </span>
                              and upload it here
                            </span>
                          ) : (
                            <span>
                              Need to create a new document? Select&nbsp;
                              <span className="font-bold">
                                Download template&nbsp;
                                {/* &nbsp;{requirementResults.category}&nbsp; */}
                              </span>
                              to get started.
                            </span>
                          )}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    {employee && !sessionStorage.getItem('remote_user') ? (
                      <div>
                        <div className="page-sub-details-final page-sub-margin">
                          Ready to mark a document as final? Please review the Document Title naming convention. For additional naming, go to the&nbsp;
                          <span className="font-bold">Actions</span>
                          menu, then&nbsp;
                          <span className="font-bold">Edit.</span>
                        </div>
                        <div className="page-sub-details-final page-sub-margin">
                          After you’ve renamed the file, select the checkbox to
                          mark the document as final. Then select&nbsp;
                          <span className="font-bold">Save.</span>
                          (You’ll also want to select&nbsp;
                          <span className="font-bold">Save</span>
                          if you unmark a document as final.)
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}

                <div id="react-file-drop-space" className="margin-top-bot">
                  <div id="react-file-drop-space" className="margin-top-bot">
                    <Dropzone
                      onDrop={(e) =>
                        onDrop(
                          e,
                          setReportingDetailsData,
                          setSuccessFlag,
                          setFailedFlag,
                          setMessageContent,
                          setLoading,
                          setUploadedFlag,
                          setSelectAll,
                          setCount,
                          setDownloadEnabled,
                          showWarning
                        )
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dragndropspace">
                          <input {...getInputProps()} />
                          <div
                            className="upload-big-icon"
                            data-testid="Upload file"
                          />
                          &nbsp;Drag and drop files here or&nbsp;
                          <span>browse</span>
                          &nbsp;to upload
                        </div>
                      )}
                    </Dropzone>
                  </div>

                  {/* <div id="changes-react-file" className="upload-text-new">
                Please drag or browse to add up to 10 documents here, then select upload
                 </div> */}
                  {/* <EDMDocumentUpload
                    controller={
                      new PlatformController(
                        setReportingDetailsData,
                        setSuccessFlag,
                        setFailedFlag,
                        setMessageContent,
                        setLoading,
                        setUploadedFlag,
                        uploadMultipleFiles
                      )
                    }
                    content={defaultContent}
                  >
                    <UploadView cancelUpload={true} maxFileCount={10} />
                  </EDMDocumentUpload> */}
                </div>
                {reportingDetailsData.length === 0 ? (
                  ''
                ) : (
                  <div>
                    {employee && !sessionStorage.getItem('remote_user') ? (
                      <div className="document-submit">
                        <div className="button-description">
                          Select&nbsp;
                          <strong>Save</strong>
                          &nbsp;to save any changes. Any documents marked as
                          final will be saved to FileNet. 
                          
                          {!disableSubmit?(<> If the documents need
                          to be submitted for U.S. Bancorp Impact Finance review, select{' '}
                          <strong>Submit for review.</strong></>):(<> For Submitting for review, at least one document should be in 'Uploaded' status.</>)}
                        </div>
                        <div className="button-details">
                          <div className="">
                            {tempUserRole === 'employee' ? (
                              <div className="single-buttons">
                                <USBButton
                                  variant="primary"
                                  handleClick={() =>
                                    saveFiles(
                                      reportingDetailsData,
                                      setLoading,
                                      setSuccessFlag,
                                      setFailedFlag,
                                      setMessageContent,
                                      setReportingDetailsData,
                                      setSavedFlag,
                                      setIsDirty,
                                      setSelectMarkfinal,
                                      setSelectAll,
                                      setDownloadEnabled,
                                      downloadEnabled,
                                      setCount,
                                      removeWarning
                                    )
                                  }
                                >
                                  Save
                                </USBButton>
                              </div>
                            ) : ''}
                            <div className="single-buttons" title="For Submitting for review, at least one document should be in 'Uploaded' status.">
                              <USBButton
                                addClasses="review-button"
                                variant="primary"
                                disabled={disableSubmit}
                                handleClick={() =>
                                  showSubmitForReviewModal(
                                    setLoading,
                                    setSuccessFlag,
                                    setFailedFlag,
                                    setMessageContent,
                                    setRequirementResults,
                                    setDocTemplateFlag,
                                    setSavedFlag,
                                    setUploadedFlag,
                                    setIsDirty,
                                    removeWarning,
                                    requirementResults
                                  )
                                }
                              >
                                Submit for review
                              </USBButton>
                            </div>
                            <div className="single-buttons">
                              <USBButton
                                variant="secondary"
                                handleClick={() => showViewPortfolioModal(removeWarning)}
                              >
                                Cancel
                              </USBButton>
                            </div>
                            <div className="single-buttons">
                              <USBButton
                                variant="primary"
                                handleClick={() =>
                                  massDownloadDocument(
                                    setLoading,
                                    getDocumentListing,
                                    setReportingDetailsData,
                                    count,
                                    setCount,
                                    setDownloadEnabled,
                                    downloadEnabled,
                                    setSelectAll,
                                    setMessageContent,
                                    setFailedFlag
                                  )
                                }
                                disabled={!downloadEnabled}
                              >
                                <USBIconDownload
                                  colorVariant={
                                    downloadEnabled ? 'interaction' : 'grey60'
                                  }
                                  title="download"
                                  desc="download File"
                                  ariaHidden={true}
                                  id="add-icon"
                                  focusable={true}
                                  tabindex={-1}
                                />
                                {count > 0
                                  ? 'Download ' + count + ' files'
                                  : 'Download'}
                              </USBButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="submit-for-review-text">
                          

                          {!disableSubmit?(<>Ready to submit these documents for review?</>):(<> For Submitting for review, at least one document should be in 'Uploaded' status.</>)}
                        

                        </div>
                        <div className="button-details">
                          <div className="">
                            <div className="single-buttons" title="For Submitting for review, at least one document should be in 'Uploaded' status.">
                              <USBButton
                                variant="primary"
                                disabled={disableSubmit}
                                handleClick={() =>
                                  showSubmitForReviewModal(
                                    setLoading,
                                    setSuccessFlag,
                                    setFailedFlag,
                                    setMessageContent,
                                    setRequirementResults,
                                    setDocTemplateFlag,
                                    setUploadedFlag,
                                    setSavedFlag,
                                    setIsDirty,
                                    removeWarning,
                                    requirementResults
                                  )
                                }
                              >
                                Submit for review
                              </USBButton>
                            </div>
                            <div className="single-buttons">
                              <USBButton
                                variant="secondary"
                                handleClick={() => showViewPortfolioModal(removeWarning)}
                              >
                                Cancel
                              </USBButton>
                            </div>
                            <div className="single-buttons">
                              <USBButton
                                variant="primary"
                                handleClick={() =>
                                  massDownloadDocument(
                                    setLoading,
                                    getDocumentListing,
                                    setReportingDetailsData,
                                    count,
                                    setCount,
                                    setDownloadEnabled,
                                    downloadEnabled,
                                    setSelectAll,
                                    setMessageContent,
                                    setFailedFlag
                                  )
                                }
                                disabled={!downloadEnabled}
                              >
                                <USBIconDownload
                                  colorVariant={
                                    downloadEnabled ? 'interaction' : 'grey60'
                                  }
                                  title="download"
                                  desc="download File"
                                  ariaHidden={true}
                                  id="add-icon"
                                  focusable={true}
                                  tabindex={-1}
                                />
                                {count > 0
                                  ? 'Download ' + count + ' files'
                                  : 'Download'}
                              </USBButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <DocumentList
                  setLoading={setLoading}
                  reportingDetailsData={reportingDetailsData}
                  openedAccordion={openedAccordion}
                  setOpenedAccordion={setOpenedAccordion}
                  employee={employee}
                  actionsDrop={actionsDrop}
                  setActionsDrop={setActionsDrop}
                  getDocumentListing={getDocumentListing}
                  getDocumentListingNoAPI={getDocumentListingNoAPI}
                  requirementResults={requirementResults}
                  setSuccessFlag={setSuccessFlag}
                  setFailedFlag={setFailedFlag}
                  setMessageContent={setMessageContent}
                  setDownloadEnabled={setDownloadEnabled}
                  setDeleteId={setDeleteId}
                  setErrorModal={setErrorModal}
                  setErrorModalTxt={setErrorModalTxt}
                  setErrorModalTitle={setErrorModalTitle}
                  setReportingDetailsData={setReportingDetailsData}
                  setCloseRenameModal={setCloseRenameModal}
                  setRenamedTextReceived={setRenamedTextReceived}
                  setCurrentFilenetId={setCurrentFilenetId}
                  setLegalHold={setLegalHold}
                  setFileDownload={setFileDownload}
                  count={count}
                  setCount={setCount}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  selectMarkfinal={selectMarkfinal}
                  setSelectMarkfinal={setSelectMarkfinal}
                  setIsDirty={setIsDirty}
                  removeWarning={removeWarning}
                  showWarning={showWarning}
                  setDisableSubmit={setDisableSubmit}
                />
              </TabPanel>
              <TabPanel header="Messages">
                <div className="page-sub-details">
                  {allMessages !== null && allMessages.length >= 1 ? (
                    <span>
                      You’ll see the most recent message listed first. Posting a
                      new message? Your comment can be up to 300 characters.
                    </span>
                  ) : (
                    <span>
                      You don’t have any messages for this reporting
                      requirement. Want to post a new message? Your comment can
                      be up to 300 characters.
                    </span>
                  )}
                  <div className="textpanel">
                    <textarea
                      maxLength="301"
                      aria-label="message"
                      className={charCountWarn ? 'ta-error' : 'ta-comments'}
                      placeholder="Type a new message"
                      onChange={(e) =>
                        handleWordCount(
                          e,
                          setCommentTxt,
                          setCommentCharsLeft,
                          setCharCountWarn
                        )
                      }
                      value={commentTxt}
                    />
                    {charCountWarn ? <span className="char-alert" /> : ''}
                    <span className="chars-left">
                      {commentCharsLeft === 301 ? (
                        charCountWarn
                      ) : (
                        <span>
                          {commentCharsLeft}
                          /300 &nbsp;{' '}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="post-button-panel">
                    <USBButton
                      variant="primary"
                      size="small"
                      handleClick={() =>
                        submitMessageFn(
                          commentTxt,
                          setCommentTxt,
                          setCommentCharsLeft,
                          setLoading,
                          setAllMessages,
                          setCharCountWarn
                        )
                      }
                    >
                      Post message
                    </USBButton>
                  </div>
                  <div className="comments-panel">
                    {allMessages.map((item, index) => (
                      <div
                        className="listContainer comment-section bordered"
                        tabIndex="0"
                        id={`message${index}`}
                      >
                        <div className="author-detail">
                          <span className="name-section">{item.userName}</span>
                          <span className="time-section">
                            {item.commentsTimeStamp}
                            &nbsp; &nbsp;
                            {item.commentsDate}
                          </span>
                        </div>
                        <div className="comment">{item.comment}</div>
                      </div>
                    ))}
                  </div>
                  <div className="clear-fix">&nbsp;</div>
                </div>
              </TabPanel>



              <TabPanel headerClassName={tempUserRole && tempUserRole === 'employee' && (!sessionStorage.getItem('remote_user')) ? "" : "hide-tabs"} header="PSA Messages">
                <div className="page-sub-details">
                  {allMessagesPSA !== null && allMessagesPSA.length >= 1 ? (
                    <span>
                      You’ll see the most recent psa message listed first. Posting a
                      new message? Your comment can be up to 300 characters.
                    </span>
                  ) : (
                    <span>
                      You don’t have any psa messages for this reporting
                      requirement. Want to post a new message? Your comment can
                      be up to 300 characters.
                    </span>
                  )}
                  <div className="textpanel">
                    <textarea
                      maxLength="301"
                      aria-label="message"
                      className={charCountWarnPSA ? 'ta-error' : 'ta-comments'}
                      placeholder="Type a new message"
                      onChange={(e) =>
                        handleWordCount(
                          e,
                          setCommentTxtPSA,
                          setCommentCharsLeftPSA,
                          setCharCountWarnPSA
                        )
                      }
                      value={commentTxtPSA}
                    />
                    {charCountWarnPSA ? <span className="char-alert" /> : ''}
                    <span className="chars-left">
                      {commentCharsLeftPSA === 301 ? (
                        charCountWarnPSA
                      ) : (
                        <span>
                          {commentCharsLeftPSA}
                          /300 &nbsp;{' '}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="post-button-panel">
                    <USBButton
                      variant="primary"
                      size="small"
                      handleClick={() =>
                        submitMessageFnPSA(
                          commentTxtPSA,
                          setCommentTxtPSA,
                          setCommentCharsLeftPSA,
                          setLoading,
                          setAllMessagesPSA,
                          setCharCountWarnPSA
                        )
                      }
                    >
                      Post message
                    </USBButton>
                  </div>
                  <div className="comments-panel">
                    {allMessagesPSA.map((item, index) => (
                      <div
                        className="listContainer comment-section bordered"
                        tabIndex="0"
                        id={`message${index}`}
                      >
                        <div className="author-detail">
                          <span className="name-section">{item.userName}</span>
                          <span className="time-section">
                            {item.commentsTimeStamp}
                            &nbsp; &nbsp;
                            {item.commentsDate}
                          </span>
                        </div>
                        <div className="comment">{item.comment}</div>
                      </div>
                    ))}
                  </div>
                  <div className="clear-fix">&nbsp;</div>
                </div>
              </TabPanel>

            </TabView>
          </div>
        </USBColumn>
      </USBGrid>
    </>
  );
};

export default RequirementDetails;
