// @flow

import React from 'react';
import { USBButton, USBInput, USBProgressIndicator, USBSelect, USBNotification } from '@usb-shield/components-react/dist/cjs';
import { connect } from "react-redux";
import NavigateBack from '../../components/shared/navigateBack';
import Axios from 'axios';
import GraphQlQuery from '../../utilities/graphQL-query';
import { getSvcServiceURL } from '../../config/connections';
import LoadingHelper from '../../utilities/loading-helper';
import UserRoleDropdownList from './user-role-dropdown-list';
import { UserRoleConfig } from '../../config/user-role-config.js';
import UserInformationForm from './UserInformationForm.js';
import ProgressIndicator from '../../components/shared/ProgressIndicator';
import type { ContentValueModel } from '../../types/common.types';
import type { UserProfileFormModel, CountryListResponseModel } from './types';
import type { MapStateToProps } from "react-redux"
import verifyEmailAlreadyExist from '../../shared/thunk/email-validation';
import { FormValidationHelpers } from '../../utilities/form-validation-helpers';
import { transmitIframeMessage } from '../../components/utility/transmit-message';

type Props = {
    parentCompany: Object;
    profileData: UserProfileFormModel;
    userType: Object;
    currentUser: Object,
    countryList: ContentValueModel[],
    isFrequentVendor: boolean;
    location: any,
    dispatch: Function,
    history: Object
}

type State = {
    userDetails: UserProfileFormModel,
    countryList: ContentValueModel[],
    roleList: ContentValueModel[],
    emailAlreadyExist: boolean
}

class EmployeeRegistration extends React.Component<Props, State> {

    userRoleDropdownList = new UserRoleDropdownList();
    constructor(props) {
        super(props);

        this.state = {
            userDetails: {
            },
            countryList: [],
            roleList: this.userRoleDropdownList.getUserDropdownList(),
            user: new Object(),
            isFormInvalidError: false,
            emailAlreadyExist: false,
            isBusinessPhoneTopLevelError: false,
            isMobilePhoneTopLevelError: false,
            getCurrentUserRoleApiError: false,
            getCountryStateListAPIError: false
        };
    }




    componentDidMount() {
        console.log(this.props,"props")
        this.getCountryList();
        if (this.props.profileData && Object.keys(this.props.profileData).length > 0) {
            this.setFormData(this.props.profileData);
        } else {
            this.setFormData({});
        }

    }

    setParentCompanyInformation(userId) {
        const graphURL = getSvcServiceURL({ service: 'graph' });
        const query = GraphQlQuery.getCurrentUserRole(userId);
        LoadingHelper.inFlight();
        Axios.post(graphURL, query, { cdcType: "servicing" })
            .then((res) => {
                console.log(res);
                LoadingHelper.outFlight();
                const data = res.data.data;
                this.props.dispatch({
                    type: "SET_PARENT_COMPANY_INFO",
                    payload: data.getProfileWithParentComType
                });
            }).catch(error => {
                LoadingHelper.outFlight();
                console.error("There is an error loading Employee Role.", error);
                this.setState({
                    getCurrentUserRoleApiError: true
                })
            })
    }

    getCountryList() {
        if (this.props.countryList && this.props.countryList.length > 0) {
            this.setState({
                countryList: this.props.countryList
            })
            return;
        }
        LoadingHelper.inFlight();
        Axios.post(getSvcServiceURL({ service: 'graph' }), GraphQlQuery.getCountryQuery(), { cdcType: "servicing" }).then(res => {
            LoadingHelper.outFlight();
            const countryResponse: CountryListResponseModel[] = res.data.data.getCountryStateList;
            const countryListDropDown = countryResponse.map(item => {
                return {
                    content: item.countryInfo.countryName,
                    value: item.countryInfo.countryCode
                }
            });
            this.props.dispatch({
                type: 'ADD_COUNTRY_LIST',
                payload: countryListDropDown
            })
            this.setState({
                countryList: countryListDropDown
            })
        }).catch((error) => {
            LoadingHelper.outFlight();
            console.error("There is an error loading country list.", error);
            this.setState({
                getCountryStateListAPIError: true
            })
        });
    }

    setFormData(data: UserProfileFormModel) {
        const countryCode = data.address ? data.address.country : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.country || 'US' : 'US');
        this.setState({
            userDetails: {
                firstName: data.firstName ? data.firstName : '',
                middleName: data.middleName ? data.middleName : '',
                lastName: data.lastName ? data.lastName : '',
                profileRole: data.profileRole ? data.profileRole : '',
                email: data.email ? data.email : '',
                phoneNumbers: {
                    mobilePhone: data.phoneNumbers ? data.phoneNumbers.mobilePhone : '',
                    businessPhone: data.phoneNumbers ? data.phoneNumbers.businessPhone : ''
                },
                mnoBypass:data.mnoBypass ? data.mnoBypass : false,
                address: {
                    addressLine1: data.address ? data.address.addressLine1 : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.addressLine1 : ''),
                    addressLine2: data.address ? data.address.addressLine2 : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.addressLine2 : ''),
                    city: data.address ? data.address.city : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.city : ''),
                    state: data.address ? data.address.state : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.state : ''),
                    zipCode: data.address ? data.address.zipCode : (this.props.parentCompany && this.props.parentCompany.address && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.address.zipCode : ''),
                    country: countryCode === 'USA' ? 'US' : countryCode
                },
                userId: data.userId ? data.userId : '',
                profileRole: data.profileRole ? data.profileRole : this.userRoleDropdownList.getProfileRole(),
                parentCompanyName: data.parentCompanyName ? data.parentCompanyName : (this.props.parentCompany && !this.userRoleDropdownList.isUserTypeNotListedVendor() ? this.props.parentCompany.parentCompanyName : '')
            },
        });
    }

    onChangeText = (userDetails: UserProfileFormModel) => {
        if (this.state.emailAlreadyExist && this.state.userDetails.email !== this.state.user.email) {
            this.setState({
                emailAlreadyExist: false
            })
        }
        this.setState((state) => {
            return {
                user: userDetails

            }
        })

    }


    onPhoneNumberChange = (userDetails: UserProfileFormModel) => {
        let isBusinessPhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.businessPhone) ? true : false;
        let isMobilePhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.mobilePhone) ? true : false;

        this.setState((state) => {
            return {
                isFormInvalidError: false,
                isBusinessPhoneTopLevelError: isBusinessPhoneTopLevelError,
                isMobilePhoneTopLevelError: isMobilePhoneTopLevelError
            }
        })

    }

    onContinueClick = (userDetails: UserProfileFormModel, isFormValid: Boolean, isFormInvalidError: Boolean) => {
        let isBusinessPhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.businessPhone) ? true : false;
        let isMobilePhoneTopLevelError = FormValidationHelpers.validateMobileNumber(userDetails.phoneNumbers.mobilePhone) ? true : false;
        if (isFormValid) {
            let shouldCheckEmail=true;
            try{
                if(this.props.userType.subType.parentCompanyName === "Not Listed"){
                    shouldCheckEmail=false;
                }

            }catch{
                    // Nothing
            }
            if(shouldCheckEmail){
                this.props.dispatch(verifyEmailAlreadyExist(userDetails.email)).then(response => {
                    this.continueForward(userDetails);
                }).catch(error => {
                    if (error.emailExist) {
                        this.setState({
                            emailAlreadyExist: true
                        })
                        transmitIframeMessage.scrollUp();
                    } else {
                        this.continueForward(userDetails);
                    }
                })
            }else{
                this.continueForward(userDetails);
            }
        } else {

            this.setState((state) => {
                return {
                    user: userDetails,
                    isFormInvalidError: isFormInvalidError,
                    isBusinessPhoneTopLevelError: isBusinessPhoneTopLevelError,
                    isMobilePhoneTopLevelError: isMobilePhoneTopLevelError
                }
            })
            if (isFormInvalidError || isBusinessPhoneTopLevelError || isMobilePhoneTopLevelError) {
                transmitIframeMessage.scrollUp();
            }
        }
    }


    continueForward = (userDetails) => {
        this.props.dispatch({
            type: "CREATE_PROFILE",
            payload: userDetails
        });
        const routeData = {
            profileId: this.props.location.state && this.props.location.state.profileId ? this.props.location.state.profileId : null
        }

        if (userDetails.profileRole === this.userRoleDropdownList.roleKeys.contributor || userDetails.profileRole === this.userRoleDropdownList.roleKeys.contributorVendor) {
            this.props.history.push('/ManageUser/permissions', routeData);
        } else {
            this.props.history.push('/ManageUser/profile-review', routeData);
        }
    }

    onJumpLinkClick = () => {
        setTimeout(() => {
            let str = window.location.hash;
            this.handleFieldFocus(str);
        }, 300);

    }


    handleFieldFocus = (type: string): void => {
        switch (type) {
            case "#first-name":
                document.getElementById('usrfirstname').getElementsByTagName('input')[0].focus();
                break;
            case "#last-name":
                document.getElementById('usrlastname').getElementsByTagName('input')[0].focus();
                break;
            case "#usrRole":
                document.getElementById('role-select-id').focus();
                break;
            case "#email-address":
                document.getElementById('usremail').getElementsByTagName('input')[0].focus();
                break;
            case "#mobile-number":
                document.getElementById('usrmobilephone').getElementsByTagName('input')[0].focus();
                break;
            case "#company-name": 
                document.getElementById('usrparentcompanyname').getElementsByTagName('input')[0].focus();
                break;   
            default:
                break;
        }
    }


    onCancelClick = () => {
        let path = '/ManageUser/';
        this.props.history.push(path);
    }


    getBackNavigationUrl(): string {
        if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.superAdmin)) {
            if (this.props.parentCompany?.businessProfileType?.toLowerCase() === 'customer') {
                return '/ManageUser/user-type';
            } else {
                return '/ManageUser/parent-company';
            }
        } else if (this.props.currentUser.custom_data.role.toLowerCase().includes(UserRoleConfig.customer) && !this.props.isFrequentVendor) {
            return '/ManageUser/user-type';
        } else if (this.props.isFrequentVendor) {
            return '/digital/servicing/ui/customer-profile'
        }
        // return '';
    }

    resetNotification = () => {
        this.setState({
            getCurrentUserRoleApiError: false,
            getCountryStateListAPIError: false
        })
      }

    render() {

        return (
            <React.Fragment>
                <ProgressIndicator id={'progressbar'}
                    currentStep={2} />
                <div className="sp-container-fluid sp-progress-content-space">
                    <div className="sp-row">

                        <div className="sp-col-6 sp-offset-left-3">
                            <NavigateBack url={this.getBackNavigationUrl()} />
                            {/* <div className=""> */}
                            <h1 className="sp-page-title sp-mr-b-16">Enter the user’s information.</h1>
                            {/* </div> */}
                            {this.state.getCurrentUserRoleApiError || this.state.getCountryStateListAPIError ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick}>
                                    <USBNotification variant='error' addClasses="sp-mr-b-12" 
                                        roleType='alert'
                                        handleClose={this.resetNotification}
                                        notificationData={[
                                        {
                                            text: "There was a problem processing your request. Please try again."
                                        }
                                    ]} /></div>
                                : null}
                            {
                                this.state.emailAlreadyExist ?
                                    <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                        <USBNotification variant="error" roleType='alert' >
                                            <a href="#email-address">Email Address</a> already exists.
                                        </USBNotification>
                                    </div> : null
                            }
                            {this.state.isFormInvalidError && this.state.isMobilePhoneTopLevelError && this.state.user.firstName !== '' && this.state.user.lastName !== '' && this.state.user.profileRole !== '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick}>
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter a valid',
                                            linkText: 'mobile phone number.',
                                            link: '#mobile-number'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName === '' && this.state.user.profileRole === '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick}>
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                        text: 'Enter the user’s',
                                        linkText: 'first name.',
                                        link: '#first-name'
                                    }, {
                                        text: 'Enter the user’s',
                                        linkText: 'last name.',
                                        link: '#last-name'
                                    }, {
                                        text: 'Select the user’s',
                                        linkText: 'role type.',
                                        link: '#usrRole'
                                    }, {
                                        text: 'Enter a valid',
                                        linkText: 'email address.',
                                        link: '#email-address'
                                    }
                                  
                                    ]} /></div>
                                : null}

                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName === '' && this.state.user.profileRole === '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                        text: 'Enter the user’s',
                                        linkText: 'last name.',
                                        link: '#last-name'
                                    }, {
                                        text: 'Select the user’s',
                                        linkText: 'role type.',
                                        link: '#usrRole'
                                    }, {
                                        text: 'Enter a valid',
                                        linkText: 'email address.',
                                        link: '#email-address'
                                    }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName !== '' && this.state.user.profileRole === '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick}>
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                        text: 'Enter the user’s',
                                        linkText: 'first name.',
                                        link: '#first-name'
                                    }, {
                                        text: 'Select the user’s',
                                        linkText: 'role type.',
                                        link: '#usrRole'
                                    }, {
                                        text: 'Enter a valid',
                                        linkText: 'email address.',
                                        link: '#email-address'
                                    }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName === '' && this.state.user.profileRole !== '' && this.state.user.email === ''  && this.state.user.parentCompanyName === ''?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                        text: 'Enter the user’s',
                                        linkText: 'first name.',
                                        link: '#first-name'
                                    }, {
                                        text: 'Enter the user’s',
                                        linkText: 'last name.',
                                        link: '#last-name'
                                    }, {
                                        text: 'Enter a valid',
                                        linkText: 'email address.',
                                        link: '#email-address'
                                    },
                                    {
                                        text: 'Enter the user’s',
                                        linkText: 'Company name.',
                                        link: '#company-name'   

                                    }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName === '' && this.state.user.profileRole == '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[{
                                        text: 'Enter the user’s',
                                        linkText: 'first name.',
                                        link: '#first-name'
                                    }, {
                                        text: 'Enter the user’s',
                                        linkText: 'last name.',
                                        link: '#last-name'
                                    }, {
                                        text: 'Select the user’s',
                                        linkText: 'role type.',
                                        link: '#usrRole'
                                    }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName !== '' && this.state.user.profileRole === '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Select the user’s',
                                            linkText: 'role type.',
                                            link: '#usrRole'
                                        }, {
                                            text: 'Enter a valid',
                                            linkText: 'email address.',
                                            link: '#email-address'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName === '' && this.state.user.profileRole !== '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'first name.',
                                            link: '#first-name'
                                        }, {
                                            text: 'Enter the user’s',
                                            linkText: 'last name.',
                                            link: '#last-name'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName !== '' && this.state.user.profileRole !== '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'first name.',
                                            link: '#first-name'
                                        }, {
                                            text: 'Enter a valid',
                                            linkText: 'email address.',
                                            link: '#email-address'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName === '' && this.state.user.profileRole !== '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'last name.',
                                            link: '#last-name'
                                        }, {
                                            text: 'Enter a valid',
                                            linkText: 'email address.',
                                            link: '#email-address'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName === '' && this.state.user.profileRole === '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'last name.',
                                            link: '#last-name'
                                        }, {
                                            text: 'Select the user’s',
                                            linkText: 'role type.',
                                            link: '#usrRole'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName !== '' && this.state.user.profileRole === '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'first name.',
                                            link: '#first-name'
                                        }, {
                                            text: 'Select the user’s',
                                            linkText: 'role type.',
                                            link: '#usrRole'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName !== '' && this.state.user.profileRole !== '' && this.state.user.email === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter a valid',
                                            linkText: 'email address.',
                                            link: '#email-address'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName !== '' && this.state.user.profileRole === '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Select the user’s',
                                            linkText: 'role type.',
                                            link: '#usrRole'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName === '' && this.state.user.profileRole !== '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'last name.',
                                            link: '#last-name'
                                        }
                                    ]} /></div>
                                : null}
                            {this.state.isFormInvalidError && this.state.user.firstName === '' && this.state.user.lastName !== '' && this.state.user.profileRole !== '' && this.state.user.email !== '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'first name.',
                                            link: '#first-name'
                                        }
                                    ]} /></div>
                                : null}
                             {this.state.isFormInvalidError && this.state.user.firstName !== '' && this.state.user.lastName !== '' && this.state.user.profileRole !== '' && this.state.user.email !== '' && this.state.user.parentCompanyName === '' ?
                                <div className="sp-width-100" onClick={this.onJumpLinkClick} >
                                    <USBNotification variant='error' id='multiple' addClasses="sp-mr-b-12" roleType='alert' notificationData={[
                                        {
                                            text: 'Enter the user’s',
                                            linkText: 'Company name.',
                                            link: '#company-name'
                                        }
                                    ]} /></div>
                                : null}
                            <UserInformationForm title=""
                                onCancelHandler={this.onCancelClick}
                                onSubmitHandler={this.onContinueClick}
                                onChangeTextHandler={this.onChangeText}
                                handlePhoneNumberChangeHandler={this.onPhoneNumberChange}
                                countryList={this.state.countryList}
                                roleList={this.state.roleList}
                                isUserTypeNotListedVendor={this.userRoleDropdownList && this.userRoleDropdownList.isUserTypeNotListedVendor()}
                                formData={this.state.userDetails}
                                readOnlyFields={['companyName']}
                                emailAlreadyExist={this.state.emailAlreadyExist}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment >
        );
    }


}

const mapStateToProps: MapStateToProps = state => {
    return {
        profileData: state.userProfileInformation.profileData,
        userType: state.userProfileInformation.userType,
        parentCompany: state.parentCompanyInformation.parentCompany,
        currentUser: state.currentUser.user,
        countryList: state.userProfileInformation.countryList,
        isFrequentVendor: state.currentUser.isFrequentVendor
    };
};

export default connect(mapStateToProps)(EmployeeRegistration)
