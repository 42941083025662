import React, { useContext, useState } from 'react';
import USBDropdown from '@usb-shield/react-dropdown';
import './navmenu.css';
// import Link from 'next/link'
import { DocContext } from '../../../pages/context/DocContext';
import { PermissionRoleContext } from '../../../components/Hooks/usePermissionRoles';
import { FeatureFlagsContext } from '../../../components/Hooks/useFeatureFlags';
import logo from '../../../assets/images/usbank.png';
import useUserRole from '../../Hooks/use-UserRole'
import { enableClosingBinders } from '../../../config/connections';
import { useHistory } from 'react-router-dom';

const NavMenu = () => {
  const history = useHistory();
  const userCheck = useUserRole()
  const reportDropdownValues = [
    { id: 1, value: 'Customer Access Report', isSelectable: true },
    { id: 2, value: 'Lookup Report', isSelectable: true },
    {
      id: 3,
      value: 'Portal Submission',
      isSelectable: true,
    },
    { id: 4, value: 'PSA Filter', isSelectable: true },
    { id: 5, value: 'Vendor Access', isSelectable: true },
    { id: 6, value: 'Vendor User', isSelectable: true },
  ];



  const { permissionDetails } = useContext(PermissionRoleContext);

  const { featureDetails } = useContext(FeatureFlagsContext);
  const { userType, servicingUserRole, currentMenu } = useContext(DocContext);
  const [reportDropdownData, setReportDropdownData] = useState(reportDropdownValues);

  const gotoManageUsers = () => {
    history.push('/ManageUser');
    history.go(0);
  };

  const gotoInbox = () => {
    if (sessionStorage.getItem('remote_user') && JSON.parse(sessionStorage.getItem('remote_user'))?.onlyPortfolio) {

      if (confirm("Are you sure you want to navigate to Inbox page? This will reset view to your profile if you were impersonating any user/viewing another user's profile.") == true) {
        sessionStorage.setItem('assignedUser', sessionStorage.getItem('loggedUser'));
        sessionStorage.removeItem('remote_user');
        history.push('/Inbox');
      }

    } else {
      history.push('/Inbox');
    }
  };

  const goToTools = () => {
    history.push('/FeatureFlags');
    history.go(0);
  };

  const gotoReports = (e) => {
    e.preventDefault();
    const { id } = e.target;

    setReportDropdownData(reportDropdownValues);
    if (id == 1) {
      history.push('/Reports/CustomerAccessFilter');
    } else if (id == 2) {
      history.push('/Reports/Lookup');
    }
    else if (id == 3) {
      history.push('/Reports/PortalSubmission');
    } else if (id == 4) {

      history.push('/Reports/PSAFilter');
    }
    else if (id == 5) {
      history.push('/Reports/VendorCompanyFilter');
    }
    else {
      history.push('/Reports/VendorUserFilter');
    }
  };
  const gotoMyPortfolio = () => {

    if (sessionStorage.getItem('remote_user') && JSON.parse(sessionStorage.getItem('remote_user'))?.onlyInbox) {

      if (confirm("Are you sure you want to navigate to Portfolio page? This will reset view to your profile if you were impersonating any user/viewing another user's profile.") == true) {
        sessionStorage.setItem('assignedUser', sessionStorage.getItem('loggedUser'));
        sessionStorage.removeItem('remote_user');
        history.push('/ReportingDashboard');
      }

    } else {
      history.push('/ReportingDashboard');
    }

  };

  const removeImpersonatedForCB = (e) => {
    if (sessionStorage.getItem('remote_user')) {
      if (confirm("Are you sure you want to navigate to Closing Binders page? This will reset view to your profile if you were impersonating any user/viewing another user's profile.") == true) {
        sessionStorage.setItem('assignedUser', sessionStorage.getItem('loggedUser'));
        sessionStorage.removeItem('remote_user');
        gotoMyCB(e)
      }
    } else {
      gotoMyCB(e)
    }
  }

  const gotoMyCB = (e) => {
    e.preventDefault();
    const { id } = e.target;
    if (id == 1) {
      history.push('/ClosingBinders/Portfolio');
    } else if (id == 2) {
      history.push('/ClosingBinders/Doctype');
    } else if (id == 3) {
      history.push('/ClosingBinders/Filenettype');
    }
  };
  const exitImpersonate = () => {
    sessionStorage.removeItem('remote_user');
    sessionStorage.removeItem('sessionFilters');
    window.location.reload();
    history.go(0);
  };
  const exitViewAs = () => {
    sessionStorage.removeItem('view_as');
    sessionStorage.removeItem('sessionFilters');
    history.push('/ReportingDashboard');
  };
  const gotoDocTemplates = () => {
    history.push('/DocumentTemplate');
  };

  return (
    <>
      <nav id="menu-nav">
        <div className="container">
          <ul data-testid="link-list">
            <li>
              <div className="logo" role="img" aria-label="US Bank Logo">
                <img src={logo}></img>
              </div>
            </li>
            {["feature_landing_page_visible"].some(cb => featureDetails?.includes(cb)) ?
              (
                <li className={currentMenu === 'portfolio' ? 'active-menu' : ''}>
                  
                  <button
                    className={
                      currentMenu === 'portfolio'
                        ? 'button-as-link-bold'
                        : 'button-as-link'
                    }
                    type="button"
                    onClick={() => {
                      window.location.href = '/Welcome'
                    }}
                  >
                    Home
                  </button>
                </li>
              )
              : (
                ''
              )}
            {["portfolio"].some(cb => permissionDetails?.includes(cb)) ? (
              <li className={currentMenu === 'portfolio' ? 'active-menu' : ''}>
                {/* <Link href="/ReportingDashboard">My Portfolio</Link> */}
                <button
                  className={
                    currentMenu === 'portfolio'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  type="button"
                  onClick={gotoMyPortfolio}
                >
                  My Portfolio
                </button>
              </li>
            ) : (
              ''
            )}

            {(userCheck === 'employee') && enableClosingBinders == true && ["cbportfolio", "cbdoctype", "cbfilenettype"].some(cb => permissionDetails?.includes(cb)) ? (
              <li className={currentMenu === 'closingbinder' ? 'active-menu' : ''}>
                <USBDropdown
                  id="cb-drop"
                  addClasses={
                    currentMenu === 'closingbinder'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  items={[
                    ...permissionDetails?.includes("cbportfolio") ? [{ id: 1, value: 'CB Portfolio', isSelectable: true }] : [],
                    ...permissionDetails?.includes("cbdoctype") ? [{ id: 2, value: 'CB Doctype', isSelectable: true }] : [],
                    ...permissionDetails?.includes("cbfilenettype") ? [{ id: 3, value: 'CB FileNettype', isSelectable: true }] : [],
                  ]}
                  label="Closing Binders"
                  dropdownType="text"
                  handleChange={removeImpersonatedForCB}
                ></USBDropdown>
              </li>
            ) : (
              ''
            )}




            {["inbox"].some(cb => permissionDetails?.includes(cb)) &&
              ["feature_inbox_visible"].some(cb => featureDetails?.includes(cb)) ? (


              <li
                data-testid="inbox-link"
                className={currentMenu === 'inbox' ? 'active-menu' : ''}
              >
                <button
                  className={
                    currentMenu === 'inbox'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  type="button"
                  onClick={gotoInbox}
                >
                  Inbox
                </button>
              </li>
            ) : (
              ''
            )}


            {

              (userCheck === 'employee' || userCheck === 'portaladmin'  ||  servicingUserRole === 'siteadmin' ||
                servicingUserRole === 'vendorsiteadmin') && permissionDetails?.includes("manageusers") ? (
                <li
                  data-testid="manageuser-link"
                  className={currentMenu === 'manageuser' ? 'active-menu' : ''}
                >
                  {/* <Link href="/ManageUser">Manage Users</Link> */}
                  <button
                    className={
                      currentMenu === 'manageuser'
                        ? 'button-as-link-bold'
                        : 'button-as-link'
                    }
                    type="button"
                    onClick={gotoManageUsers}
                  >
                    Manage Users
                  </button>
                </li>


              ) : (
                ''
              )}

            {permissionDetails?.includes("inbox") && !permissionDetails?.includes("portfolio") ?

              (
                ''
              ) : (
                <li className={currentMenu === 'doctemplates' ? 'active-menu' : ''}>
                  <button
                    className={
                      currentMenu === 'doctemplates'
                        ? 'button-as-link-bold'
                        : 'button-as-link'
                    }
                    type="button"
                    onClick={gotoDocTemplates}
                  >
                    Document Templates
                  </button>
                </li>
              )}

            {(userCheck === 'employee' && permissionDetails?.includes("reports")) ? (
              <li className={currentMenu === 'reports' ? 'active-menu' : ''}>
                <USBDropdown
                  id="report-drop"
                  addClasses={
                    currentMenu === 'reports'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  items={reportDropdownData}
                  label="Reports"
                  // defaultSelected="PSA Reports"
                  dropdownType="text"
                  handleChange={gotoReports}
                ></USBDropdown>
              </li>
            ) : (
              ''
            )}




            {(userCheck === 'employee') && ["tools"].some(item => permissionDetails?.includes(item)) ? (
              <li className={currentMenu === 'tools' ? 'active-menu' : ''}>
                <USBDropdown
                  id="cb-drop"
                  addClasses={
                    currentMenu === 'tools'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  items={[
                    ...permissionDetails?.includes("tools") ? [{ id: 1, value: 'Feature Flags', isSelectable: true }] : [],
                  ]}
                  label="Tools"
                  dropdownType="text"
                  handleChange={goToTools}
                ></USBDropdown>
              </li>
            ) : (
              ''
            )}

            {sessionStorage.getItem('remote_user') ? (
              <li
                className={
                  currentMenu === 'portfolio' ? 'active-menu-remote' : ''
                }
              >
                <button
                  className={
                    currentMenu === 'portfolio'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  type="button"
                  onClick={
                    sessionStorage.getItem('remote_user')
                      ? exitImpersonate
                      : exitViewAs
                  }
                >
                  Exit User
                </button>
              </li>
            ) : (
              ''
            )}
            {/* {sessionStorage.getItem('view_as') ? (
              <li
                className={
                  currentMenu === 'portfolio' ? 'active-menu-remote' : ''
                }
              >
                <button
                  className={
                    currentMenu === 'portfolio'
                      ? 'button-as-link-bold'
                      : 'button-as-link'
                  }
                  type="button"
                  onClick={exitViewAs}
                >
                  Exit User
                </button>
              </li>
            ) : (
              ''
            )} */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavMenu;
